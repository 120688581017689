import type { SimpleMarketplaceResponse } from '@yoop/data-access-model';
import { MarketplaceType } from '@yoop/data-access-model';

/**
 * Predicate for requested marketplaces
 * @param marketplace
 */
export const requested = (marketplace: SimpleMarketplaceResponse): boolean =>
  marketplace &&
  marketplace.type &&
  (marketplace.type === MarketplaceType.DRAW || marketplace.type === MarketplaceType.AUCTION);
