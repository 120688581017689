import type { SimpleMarketplaceResponse } from '@yoop/data-access-model';
import { offerPredicate } from '@yoop/util-offer-group/predicate';
import { pendingResult } from '@yoop/util-offer/predicate';
import { active } from './active';
import { orphan } from './orphan';

/**
 * Predicate for marketplaces that have at least one active (marketplace has not ended) offer
 * @param marketplace
 */
export const hasActiveOffers = (marketplace: SimpleMarketplaceResponse): boolean =>
  marketplace &&
  marketplace.offerGroupInformation &&
  orphan(marketplace) &&
  active(marketplace) &&
  marketplace.offerGroupInformation.some(offerPredicate(pendingResult));
