import { ClientError } from './client-error';

/**
 * Represents an `ApiSauce` `TIMEOUT_ERROR` problem.
 */
export class ClientTimeoutError extends ClientError {
  constructor() {
    super('A timeout occurred while performing client request');
    Object.setPrototypeOf(this, ClientTimeoutError.prototype);
  }
}
