import type { Dayjs, UnitType } from 'dayjs';
import dayjs from 'dayjs';

/**
 * Removes milliseconds
 * @param granularity
 * @param dates
 */

export const removeMs = (granularity: UnitType, ...dates: Dayjs[]): void => {
  if (granularity !== 'millisecond') {
    dates.forEach((date) => dayjs(date).millisecond(0));
  }
};
