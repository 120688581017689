//Disabling the boundaries rule here until these libs are refactored to isolate the models.
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { TokenInfoResponse } from '@yoop/server-model/token-info';
import { OfferStatus } from '@yoop/server-model/token-info';
import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import { MarketplaceType as YoopMarketplaceType } from '@yoop/server-model/marketplace';
import type {
  EventListResponseItem,
  UserGetEventExchangeInfoResponse,
  UserActivityListSummaryResponseItem,
} from '@yoop/server-model/user-activity';
import { EventState } from '@yoop/server-model/user-activity';
import { AccessibilityType as YoopAccessibilityType } from '@yoop/server-model/event-accessibility';
import { MediaType as YoopMediaType } from '@yoop/server-model/media';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { requestedFilter } from '@yoop/util-event/helper';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { hasActiveOffers } from '@yoop/util-marketplace/predicate';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { numbers } from '@yoop/util-numbers';
import { datetime } from '@yoop/util-datetime';
import { isNilOrEmpty } from '@yoop/util';
import type { EventData } from '@yoop/whitelabel-data';
import { MarketplaceType } from '@yoop/whitelabel-data';
import { AccessibilityType, ExchangeStatus } from '@yoop/whitelabel-data';
import { mediaFrom } from '@yoop/whitelabel-media';
import { parseInt } from 'lodash';
import isNil from 'ramda/src/isNil';
import { EventStatus } from '@yoop/whitelabel-data';

export type YoopUserActivityListSummaryResponseItem = UserActivityListSummaryResponseItem;

export const mapEventsData = (
  publicEvents: EventListResponseItem[],
  userEvents: YoopUserActivityListSummaryResponseItem[],
) => {
  const summaryMap: Record<number, YoopUserActivityListSummaryResponseItem> = userEvents.reduce(
    (data, event) => ({
      ...data,
      [parseInt(event.id)]: event,
    }),
    {},
  );
  return publicEvents.map((data): EventData => {
    const userInfo = summaryMap[data.id];
    return {
      id: data.id,
      name: data.shortName,
      announcement: data.announcement,
      currency: data.currency,
      endDate: data.eventEndDate,
      startDate: data.eventStartDate,
      minPrice: isNilOrEmpty(data.minQualifyingPrice)
        ? undefined
        : numbers.money(data.minQualifyingPrice, data.currency),
      locationName: data.locationName,
      vanityName: data.vanityName,
      media: data.media?.filter((media) => media.mediaType === YoopMediaType.IMAGE).map(mediaFrom),
      eventAccessibilityType:
        data.eventAccessibilityType === YoopAccessibilityType.LIVE_ATTENDANCE
          ? AccessibilityType.LiveAttendance
          : AccessibilityType.Streaming,
      isSoldOut: data.soldOut,
      activeMarketplace: {
        type: marketplaceTypeFrom(data.activeMarketplaceType),
        numberOfListings: data.offerGroupCountInActiveMarketplace,
      },
      canGetMoreYoop: data.soldOut && !data.exchangeInfo?.exchangeEnabled,
      didRequestYoop: requestedFilter(userInfo?.marketplaceInformation),
      hasYoop: hasYoop({
        marketplaceInformation: userInfo?.marketplaceInformation,
        state: data.state,
        tokenInformation: userInfo?.tokenInformation,
        assigneeTokenInformation: userInfo?.assigneeTokenInformation,
      }),
      exchangeStatus: getExchangeStatus(data.exchangeInfo),
      status: getStatus(data, userInfo?.marketplaceInformation),
    };
  });
};
const getStatus = (
  event: EventListResponseItem,
  marketplaceInformation: SimpleMarketplaceResponse[] | undefined,
): EventStatus => {
  if (datetime.isUpcoming(datetime.parse(event.earliestMarketplaceStartDateTime))) {
    return EventStatus.New;
  }
  if (
    event.activeMarketplaceType &&
    !isNil(event.minQualifyingPrice) &&
    datetime.isUpcoming(datetime.parse(event.activeMarketplaceEndDateTime))
  ) {
    if (marketplaceInformation?.some(hasActiveOffers)) {
      return EventStatus.Requested;
    }
    return EventStatus.Active;
  }
  return event.soldOut ? EventStatus.SoldOut : EventStatus.Closed;
};

const getExchangeStatus = (exchangeInfo: UserGetEventExchangeInfoResponse | undefined) => {
  if (!exchangeInfo?.exchangeEnabled) {
    return ExchangeStatus.Disabled;
  }
  return exchangeInfo.dynamicPricingEnabled ? ExchangeStatus.Offer : ExchangeStatus.Request;
};

const hasYoop = ({
  tokenInformation,
  assigneeTokenInformation,
  marketplaceInformation,
  state,
}: {
  tokenInformation: TokenInfoResponse[];
  assigneeTokenInformation?: TokenInfoResponse[];
  state: EventState;
  marketplaceInformation: SimpleMarketplaceResponse[];
}) => {
  if (!isNilOrEmpty(tokenInformation) || !isNilOrEmpty(assigneeTokenInformation)) {
    return true;
  }
  if (state !== EventState.CANCELLED) {
    //Check if the user has won any tokens for that event.
    return marketplaceInformation?.some(
      (marketplace) =>
        isNilOrEmpty(marketplace.parentMarketplaceId) &&
        marketplace.offerGroupInformation?.some(
          ({ activeOffer }) => activeOffer?.offerStatus === OfferStatus.WON_PAYMENT_SUCCESSFUL,
        ),
    );
  }
  return false;
};

export const marketplaceTypeFrom = (type: YoopMarketplaceType) => {
  switch (type) {
    case YoopMarketplaceType.PURCHASE:
      return MarketplaceType.DirectPurchase;
    case YoopMarketplaceType.AUCTION:
      return MarketplaceType.MyPrice;
    case YoopMarketplaceType.DRAW:
      return MarketplaceType.Wishlist;
    case YoopMarketplaceType.SECONDARY_EXCHANGE:
      return MarketplaceType.Resale;
    default:
      return undefined;
  }
};
