import { AccessibilityType } from '@yoop/server-model/event-accessibility';
import { CategoryType } from '@yoop/server-model/inventory-category';
import type { ResaleInventoryItem } from '@yoop/server-model/offer-group';
import { PayoutStatus as ServerPayoutStatus } from '@yoop/server-model/offer-group';
import { SeatConfigurationType } from '@yoop/server-model/seating';
import type { EventSecondaryListings } from '@yoop/server-model/user-activity';
import { isNilOrEmpty } from '@yoop/util';
import type { MyYoopResaleEvent, ResaleToken } from '@yoop/whitelabel-data';
import {
  EventAccessibility,
  PayoutStatus,
  SeatingType,
  TokenInventoryType,
} from '@yoop/whitelabel-data';
import { mediaFrom } from '@yoop/whitelabel-media';
import { getTokenType, tokenChoiceFrom } from '../token.helper';
import { eventStateFrom } from './common.helper';

export const resaleEventFrom = (response: EventSecondaryListings): MyYoopResaleEvent => {
  const marketplace = response.marketplaceInformation?.[0];
  const resaleTokens =
    marketplace?.offerGroupInformation?.map(
      (token): ResaleToken => ({
        id: token.id,
        category: {
          id: token.categoryDetailResponse.id,
          type: inventoryTypeFrom(token.categoryDetailResponse.type),
        },
        media: token.categoryDetailResponse.merchandiseMedia?.map(mediaFrom) ?? [],
        choices: token.categoryDetailResponse.choiceSets?.map(tokenChoiceFrom) ?? [],
        listedPrice: token.reservePrice,
        payoutAmount: isNilOrEmpty(token.payoutReceipt?.totalAmount)
          ? undefined
          : parseFloat(token.payoutReceipt?.totalAmount),
        type: getTokenType(token.categoryDetailResponse.choiceSets),
        description: token.description,
        status: payoutStatusFrom(token.payoutStatus),
        soldItems: token.soldInventoryItems?.map((item) =>
          resaleItemFrom(item, token.seatConfigurationType),
        ),
        unsoldItems: token.unsoldInventoryItems?.map((item) =>
          resaleItemFrom(item, token.seatConfigurationType),
        ),
      }),
    ) ?? [];

  return {
    id: response.id,
    announcement: response.announcement,
    endDateTime: response.eventEndDate,
    locationName: response.locationName,
    vanityName: response.vanityName,
    startDateTime: response.eventStartDate,
    currency: response.currency,
    defaultMedia: mediaFrom(response.defaultMedia),
    accessibilityType:
      response.eventAccessibilityType === AccessibilityType.LIVE_ATTENDANCE
        ? EventAccessibility.InPerson
        : EventAccessibility.Virtual,
    name: response.shortName,
    media: response.media?.map(mediaFrom),
    state: eventStateFrom(response.state),
    payoutDate: response.payoutDate,
    resaleTokens,
  };
};

const resaleItemFrom = (
  { inventoryItemId, parentInventoryItemId, inventoryItemSeatingData }: ResaleInventoryItem,
  seatConfigurationType: SeatConfigurationType,
) => ({
  seating: {
    type:
      seatConfigurationType === SeatConfigurationType.SEATED
        ? SeatingType.Seated
        : SeatingType.GeneralAdmission,
    section: inventoryItemSeatingData?.section.code,
    seat: inventoryItemSeatingData?.seat.code,
    row: inventoryItemSeatingData?.row.code,
  },
  parentInventoryItemId,
  id: inventoryItemId,
});

const inventoryTypeFrom = (type: CategoryType): TokenInventoryType => {
  switch (type) {
    case CategoryType.EVENT_ACCESS:
      return TokenInventoryType.EventAccess;
    case CategoryType.MERCHANDISE:
      return TokenInventoryType.Merchandise;
    default:
      return TokenInventoryType.Addon;
  }
};

const payoutStatusFrom = (status: ServerPayoutStatus): PayoutStatus => {
  switch (status) {
    case ServerPayoutStatus.PENDING:
      return PayoutStatus.Pending;
    case ServerPayoutStatus.SUCCESS:
      return PayoutStatus.Completed;
    case ServerPayoutStatus.INITIATED:
      return PayoutStatus.Started;
    case ServerPayoutStatus.FAILED:
      return PayoutStatus.Failed;
  }
};
