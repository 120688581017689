import { Locale } from '@yoop/model-i18n';
import { log } from '@yoop/service-logger';
import dayjs from 'dayjs';
import i18n from 'i18next';
import i18next from 'i18next';
import { getUserLanguage } from './get-user-language';

/**
 * Returns the current user locale
 */
export const getUserLocale = (): Locale => i18next.language as Locale;

/**
 * Sets the locale of i18n library
 */
export const setLocale = (
  locale: Locale,
  onSuccess?: (locale: string) => void,
  onError?: (locale: string) => void,
): Promise<any> =>
  i18n.changeLanguage(locale, (error) => {
    if (error) {
      if (onError) {
        onError(locale);
      } else {
        log.error(`Tried to change locale to ${locale} and got an error: ${error.toString()}`);
      }
    }

    dayjs.locale(getUserLanguage());
    if (onSuccess) {
      onSuccess(locale);
    }
  });

export const supportedLocales: Locale[] = [
  Locale.ENGLISH_US,
  Locale.ENGLISH_CA,
  Locale.FRENCH_CA,
  Locale.SPANISH_US,
];
