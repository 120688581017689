import type { MediaResponse } from './media';
import type { SeatingType } from './seating';
import type { DateOfBirth } from './user';

/*
If modified, make sure whitelabel-widgets/docs/docs/model/07_tokens.mdx is in sync so docs stay up to date.
 */

// Assignment
export interface TokenAssignment {
  assignee: AssignmentUser & { pendingAccept: boolean };
  accessControlInfo?: TokenAccessControlInfo;
  inviter: AssignmentUser | undefined;
  lastAssignmentAction: TokenAssignmentAction;
}

export type TokenAccessControlInfo =
  | {
      type: AccessControlAssigneeType.Guest;
      firstName: string;
      lastName: string;
      dateOfBirth: DateOfBirth;
    }
  | { type: AccessControlAssigneeType.GuestChild }
  | { type: AccessControlAssigneeType.User };

export interface AssignmentUser {
  globalUserId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth: DateOfBirth;
  profilePictureUrl?: string;
  isVerified: boolean;
}

export enum TokenAssignmentAction {
  Created = 'created',
  Accepted = 'accepted',
  Declined = 'declined',
  Returned = 'returned',
  Revoked = 'revoked',
  Bounced = 'bounced',
}

export enum AccessControlAssigneeType {
  User = 'user',
  Guest = 'guest',
  GuestChild = 'guestChild',
}

// Event Token

export interface EventToken {
  id: number;
  seating: {
    type: SeatingType;
    section: number;
    gates?: string[];
    row?: number;
    seat?: number;
  };
  category: {
    id: number;
    type: TokenInventoryType;
  };
  type: TokenType;
  accessed: boolean;
  isSelling: boolean;
  isOwner: boolean;
  assignment: TokenAssignment;
  parentInventoryItemId?: number;
  choices?: TokenChoice[];
  badge?: MediaResponse;
  media?: MediaResponse[];
}

export enum TokenType {
  Adult = 'adult',
  Child = 'child',
  Senior = 'senior',
}

export enum TokenInventoryType {
  EventAccess = 'eventAccess',
  Merchandise = 'merchandise',
  Addon = 'addon',
}

/*
Actions not currently returned:
watch live stream
watch upcoming stream
watch replay
 */
export interface TokenActions {
  canUpgrade: boolean;
  canReturn: boolean;
  canEditGuest: boolean;
  canRevoke: boolean;
  canSell: boolean;
  canSeeSellingListing: boolean;
  canResendInvite: boolean;
  canAssign: boolean;
  canSelfAssign: boolean;
  canOpenAccessControl: boolean;
}

export interface EventTokenDetail extends EventToken, TokenActions {}

//Resale Token

export interface ResaleToken {
  id: number;
  category: {
    id: number;
    type: TokenInventoryType;
  };
  media?: MediaResponse[];
  choices?: TokenChoice[];
  type?: TokenType;
  listedPrice: number;
  payoutAmount: number;
  description: string;
  status: PayoutStatus;
  soldItems: ResaleItem[];
  unsoldItems: ResaleItem[];
}

export enum PayoutStatus {
  Pending = 'pending',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed',
}

export interface ResaleItem {
  id: number;
  parentInventoryItemId?: number;
  seating: {
    type: SeatingType;
    section: number;
    row?: number;
    seat?: number;
  };
}

// Extras
export interface ExtrasToken extends Omit<EventToken, 'type' | 'seating'> {
  deliveryMethod: ExtraDeliveryMethod;
}

export interface ExtrasTokenDetail extends ExtrasToken, TokenActions {}

export enum ExtraDeliveryMethod {
  Shipping = 'shipping',
  PickUp = 'pickUp',
  Digital = 'digital',
}

export interface TokenChoice {
  name: string;
  selection: string;
}

export interface PendingToken {
  seatSelectionPending: boolean;
  selectionStartTime: string;
  selectionEndTime: string | undefined;
  numberOfTokens: number | undefined;
}
