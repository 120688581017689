import { addArrayParamToUrl } from './add-array-param-to-url';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import { isArray } from 'rxjs/internal-compatibility';

/**
 * Extract array parameters from a params object and properly add them to the URL.
 * We need this because if we pass array parameters to the controller, it will add
 * ?key=[value1, value2, ...] to the URL, rather than
 * ?key=value1&key=value2&key=... like we would want.
 * @param url
 * @param params
 */
export const extractArrayParams = (
  url: string,
  params?: any,
): { url: string; params?: { [key: string]: string } } => {
  if (params) {
    let newUrl = url;
    const newParams = Object.assign({}, params);
    const arrayParams: string[] = [];
    forEachObjIndexed((value: any, key: string | number | symbol) => {
      if (isArray(value)) {
        arrayParams.push(typeof key === 'string' ? key : key.toString());
      }
    }, params);
    if (arrayParams.length) {
      arrayParams.forEach((key: string) => {
        newUrl = addArrayParamToUrl(key, params[key], newUrl);
        delete newParams[key];
      });
    }
    return { url: newUrl, params: newParams };
  }
  return { url, params };
};
