import type { UserProfileApiResponse } from '@yoop/user-controller';
import { UserAuthenticationState } from '@yoop/user-controller';
import { apiClientStore } from '@yoop/whitelabel-api-client';
import type { UserProfile } from '@yoop/whitelabel-data';
import { dateOfBirthFrom } from '@yoop/whitelabel-data';

export const getUserProfile = async (): Promise<UserProfile> => {
  const { apiClient } = apiClientStore.getState();
  const profile = await apiClient.userController.getUserProfile();
  return userProfileFrom(profile);
};

const userProfileFrom = (response: UserProfileApiResponse): UserProfile => {
  return {
    globalUserId: response.globalUserId,
    dateOfBirth: dateOfBirthFrom(response.dateOfBirth),
    emailAddress: response.emailAddress,
    firstName: response.firstName,
    lastName: response.lastName,
    pictureUrl: response.profilePictureUrl,
    isEmailVerified: response.userAuthenticationState === UserAuthenticationState.EMAIL_VERIFIED,
    isPictureVerified: response.profilePictureVerificationStatus,
  };
};
