import type { UserProfile } from './models/user';

export interface PortMessage<T = any> {
  action: string;
  data?: T;
}

export enum CommsMessageActions {
  GetEvents = 'getEvents',
  Tabs = 'myYoopTabs',
  InPersonEventsList = 'inPersonEventsList',
  VirtualEventsList = 'virtualEventsList',
  RequestedEventsList = 'requestedEventsList',
  EventDetail = 'EventDetail',
  ResaleEventsList = 'resaleEventsList',
  Authentication = 'authentication',
  RevokeToken = 'revokeToken',
  ReturnToken = 'returnToken',
  ResendTokenInvite = 'resendTokenInvite',
  AssignTokenToCurrentUser = 'assignTokenToCurrentUser',
  GetConfig = 'getConfig',
}

export interface Response<T> {
  success: boolean;
  error?: Error;
  result?: T;
}

export interface EventDetailRequestData {
  eventId: number;
}

export interface EventTokenActionData {
  tokenId: number;
}

export interface AuthenticationResult {
  authenticated: boolean;
  profile?: UserProfile;
}

export interface GetConfigActionData {
  apiKey: string;
}
