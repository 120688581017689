export enum EventAccessibility {
  InPerson = 'inPerson',
  Virtual = 'virtual',
}

export enum AccessControlMode {
  External = 'external',
  Yoop = 'yoop',
}

export enum EventIdVerificationMode {
  None = 'none',
  Verified = 'verified',
  IdRequired = 'idRequired',
}
