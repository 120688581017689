import { AccessControlSubMode } from '@yoop/server-model/event-access-control';
import type { SelectedInventoryChoiceResponse } from '@yoop/server-model/inventory-category';
import {
  CategoryType,
  ChoiceTagType,
  ChoiceTagValue,
  DeliveryMethod,
} from '@yoop/server-model/inventory-category';
import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import { SeatConfigurationType } from '@yoop/server-model/seating';
import type { TokenInfoResponse } from '@yoop/server-model/token-info';
import {
  AssignmentStatus,
  OfferStatus,
  TokenAssignmentAction as ServerTokenAssignmentAction,
} from '@yoop/server-model/token-info';
import { isNilOrEmpty } from '@yoop/util';
import { datetime } from '@yoop/util-datetime';
import type {
  AssignmentUser,
  EventToken,
  EventTokenDetail,
  ExtrasToken,
  ExtrasTokenDetail,
  PendingToken,
  TokenAccessControlInfo,
  TokenActions,
  TokenAssignment,
  TokenChoice,
  UserProfile,
} from '@yoop/whitelabel-data';
import {
  AccessControlAssigneeType,
  dateOfBirthFrom,
  ExtraDeliveryMethod,
  SeatingType,
  TokenAssignmentAction,
  TokenInventoryType,
  TokenType,
} from '@yoop/whitelabel-data';
import { mediaFrom } from '@yoop/whitelabel-media';
import { groupBy, some } from 'lodash';
import isNil from 'ramda/src/isNil';

export const tokensFrom = ({
  isResaleOpen,
  assignedTokens,
  ownedTokens,
  currentUserProfile,
}: {
  ownedTokens: TokenInfoResponse[];
  assignedTokens: TokenInfoResponse[];
  isResaleOpen: boolean;
  currentUserProfile: UserProfile;
}) => {
  const tokens: EventToken[] = [];
  const extras: ExtrasToken[] = [];
  const addTokenFrom = (token: TokenInfoResponse, isOwner: boolean) => {
    switch (token.categoryDetail?.type) {
      case CategoryType.ADD_ON:
        extras.push(
          extraTokenFrom({
            token,
            tokenType: TokenInventoryType.Addon,
            isResaleOpen,
            isOwner,
            currentUserProfile,
          }),
        );
        break;
      case CategoryType.MERCHANDISE:
        extras.push(
          extraTokenFrom({
            token,
            tokenType: TokenInventoryType.Merchandise,
            isResaleOpen,
            isOwner,
            currentUserProfile,
          }),
        );
        break;
      default:
        tokens.push(eventTokenFrom({ token, isResaleOpen, isOwner, currentUserProfile }));
    }
  };
  ownedTokens?.forEach((token) => {
    addTokenFrom(token, true);
  });

  assignedTokens?.forEach((token) => {
    addTokenFrom(token, false);
  });

  return {
    tokens,
    extras,
  };
};

interface TokenInfoParams {
  isOwner: boolean;
  token: TokenInfoResponse;
  currentUserProfile: UserProfile;
  tokenType: TokenInventoryType;
  isResaleOpen: boolean;
}

const commonTokenInfoFrom = ({
  isResaleOpen,
  tokenType,
  isOwner,
  currentUserProfile,
  token,
}: TokenInfoParams) => {
  const assignment = assigmentFrom(token, currentUserProfile);
  return {
    ...token,
    id: parseInt(token.tokenId),
    category: {
      id: token.categoryId,
      type: tokenType,
    },
    isSelling: isResaleOpen && !isNil(token.sellingOfferGroupId),
    accessed: token.accessed,
    isOwner,
    lastAssignmentAction: lastAssignmentActionFrom(token.lastTokenAssignmentAction),
    buyerGlobalUserId: token.finalTokenAssignment?.purchaserGlobalUserId,
    assignment,
    parentInventoryItemId: token.parentInventoryItemId,
    choices: token.categoryDetail?.choiceSets?.map(tokenChoiceFrom) ?? [],
    badge:
      token.seasonMembershipParentData?.avatarMedia &&
      mediaFrom(token.seasonMembershipParentData?.avatarMedia),
    media: token.categoryDetail?.merchandiseMedia?.map(mediaFrom) ?? [],
    categoryDetail: token.categoryDetail,
    finalTokenAssignment: token.finalTokenAssignment,
    sellingOfferGroupId: token.sellingOfferGroupId,
    selfAssigned: token.selfAssigned,
    resaleForbiddenContext: token.resaleForbiddenContext,
  };
};

const assigmentFrom = (token: TokenInfoResponse, currentUserProfile: UserProfile) => {
  let assignment: TokenAssignment;
  const lastAssignmentAction = lastAssignmentActionFrom(token.lastTokenAssignmentAction);
  if (token.finalTokenAssignment) {
    let accessControlInfo: TokenAccessControlInfo;
    let assignee: AssignmentUser = {
      dateOfBirth: dateOfBirthFrom(token.finalTokenAssignment.assigneeDateOfBirth),
      emailAddress: token.finalTokenAssignment.assigneeEmailAddress,
      firstName: token.finalTokenAssignment.assigneeFirstName,
      isVerified: token.finalTokenAssignment.assigneeProfilePictureVerificationStatus,
      lastName: token.finalTokenAssignment.assigneeLastName,
      profilePictureUrl: token.finalTokenAssignment.assigneeProfilePictureUrl,
      globalUserId: token.finalTokenAssignment.assigneeGlobalUserId,
    };
    let inviter: AssignmentUser = {
      dateOfBirth: dateOfBirthFrom(token.finalTokenAssignment.inviterDateOfBirth),
      emailAddress: token.finalTokenAssignment.inviterEmailAddress,
      firstName: token.finalTokenAssignment.inviterFirstName,
      isVerified: token.finalTokenAssignment.inviterProfilePictureVerificationStatus,
      lastName: token.finalTokenAssignment.inviterLastName,
      profilePictureUrl: token.finalTokenAssignment.inviterProfilePictureUrl,
      globalUserId: token.finalTokenAssignment.inviterGlobalUserId,
    };
    switch (token.finalTokenAssignment.assignmentStatus) {
      case AssignmentStatus.GUEST_CHILD:
        //when status is guest or child the 'inviter' is the one holding the token.
        accessControlInfo = {
          type: AccessControlAssigneeType.GuestChild,
        };
        assignee = inviter;
        inviter = undefined;
        break;
      case AssignmentStatus.GUEST:
        accessControlInfo = {
          type: AccessControlAssigneeType.Guest,
          firstName: token.finalTokenAssignment.assigneeFirstName,
          lastName: token.finalTokenAssignment.assigneeLastName,
          dateOfBirth: dateOfBirthFrom(token.finalTokenAssignment.assigneeDateOfBirth),
        };
        assignee = inviter;
        inviter = undefined;
        break;
      default:
        //Other statuses means assignee and inviter keep their original info, and
        if (token.selfAssigned) {
          accessControlInfo = {
            type: AccessControlAssigneeType.User,
          };
        }
    }
    assignment = {
      accessControlInfo,
      assignee: {
        ...assignee,
        pendingAccept: token.finalTokenAssignment.assignmentStatus === AssignmentStatus.PENDING,
      },
      inviter,
      lastAssignmentAction,
    };
  } else {
    //current user is the assignee.
    //Only possible cases here is user has it for access control, or has the token but nobody is using it.
    assignment = {
      assignee: {
        dateOfBirth: currentUserProfile.dateOfBirth,
        emailAddress: currentUserProfile.emailAddress,
        firstName: currentUserProfile.firstName,
        isVerified: currentUserProfile.isEmailVerified,
        lastName: currentUserProfile.lastName,
        profilePictureUrl: currentUserProfile.pictureUrl,
        globalUserId: currentUserProfile.globalUserId,
        pendingAccept: false,
      },
      inviter: undefined,
      accessControlInfo: token.selfAssigned ? { type: AccessControlAssigneeType.User } : undefined,
      lastAssignmentAction: lastAssignmentActionFrom(token.lastTokenAssignmentAction),
    };
  }
  return assignment;
};

const extraTokenFrom = (params: TokenInfoParams): ExtrasToken => {
  return {
    ...commonTokenInfoFrom(params),
    deliveryMethod: deliveryMethodFrom(params.token.categoryDetail?.merchandiseDeliveryMethod),
  };
};

const eventTokenFrom = ({
  isResaleOpen,
  currentUserProfile,
  isOwner,
  token,
}: Omit<TokenInfoParams, 'tokenType'>): EventToken => {
  const seating =
    token.seatConfigurationType === SeatConfigurationType.GENERAL_ADMISSION
      ? {
          type: SeatingType.GeneralAdmission,
          gates: token.gates?.map(({ name }) => name),
          section: token.inventoryItemSeatingData.section.code,
        }
      : {
          type: SeatingType.Seated,
          section: token.inventoryItemSeatingData.section.code,
          seat: token.inventoryItemSeatingData.seat?.code,
          row: token.inventoryItemSeatingData.row?.code,
          gates: token.gates?.map(({ name }) => name),
        };
  return {
    ...commonTokenInfoFrom({
      tokenType: TokenInventoryType.EventAccess,
      isResaleOpen,
      token,
      isOwner,
      currentUserProfile,
    }),
    type: getTokenType(token.categoryDetail?.choiceSets),
    seating,
  };
};

export const detailTokensFrom = ({
  isResaleOpen,
  assignedTokens,
  ownedTokens,
  currentUserProfile,
  upgradableOffers,
  isDoorsOpen,
  accessControlSubMode,
}: {
  ownedTokens: TokenInfoResponse[];
  assignedTokens: TokenInfoResponse[];
  isResaleOpen: boolean;
  upgradableOffers: Set<number>;
  currentUserProfile: UserProfile;
  accessControlSubMode: AccessControlSubMode;
  isDoorsOpen: boolean;
}) => {
  const tokens: EventTokenDetail[] = [];
  const extras: ExtrasTokenDetail[] = [];
  const groupedTokens = groupBy(
    [...(ownedTokens ?? []), ...(assignedTokens ?? [])],
    ({ parentInventoryItemId }) => parentInventoryItemId,
  );
  const addTokenFrom = (token: TokenInfoResponse, isOwner: boolean) => {
    const related: TokenInfoResponse[] = isNil(token.parentInventoryItemId)
      ? groupedTokens[parseInt(token.tokenId)]
      : groupedTokens[token.parentInventoryItemId];
    switch (token.categoryDetail?.type) {
      case CategoryType.ADD_ON: {
        const _token = extraTokenFrom({
          token,
          tokenType: TokenInventoryType.Addon,
          isResaleOpen,
          isOwner,
          currentUserProfile,
        });
        extras.push({
          ..._token,
          ...tokenActionsFrom({
            upgradableOffers,
            assignment: _token.assignment,
            isOwner,
            isResaleOpen,
            currentGlobalUserId: currentUserProfile.globalUserId,
            related,
            token,
            isDoorsOpen,
            accessControlSubMode,
          }),
        });
        break;
      }
      case CategoryType.MERCHANDISE: {
        const _token = extraTokenFrom({
          token,
          tokenType: TokenInventoryType.Merchandise,
          isResaleOpen,
          isOwner,
          currentUserProfile,
        });
        extras.push({
          ..._token,
          ...tokenActionsFrom({
            upgradableOffers,
            assignment: _token.assignment,
            isOwner,
            isResaleOpen,
            currentGlobalUserId: currentUserProfile.globalUserId,
            related,
            token,
            isDoorsOpen,
            accessControlSubMode,
          }),
        });
        break;
      }
      default: {
        const _token = eventTokenFrom({ token, isResaleOpen, isOwner, currentUserProfile });
        tokens.push({
          ..._token,
          ...tokenActionsFrom({
            upgradableOffers,
            assignment: _token.assignment,
            isOwner,
            isResaleOpen,
            currentGlobalUserId: currentUserProfile.globalUserId,
            related,
            token,
            isDoorsOpen,
            accessControlSubMode,
          }),
        });
      }
    }
  };
  ownedTokens?.forEach((token) => {
    addTokenFrom(token, true);
  });

  assignedTokens?.forEach((token) => {
    addTokenFrom(token, false);
  });

  return {
    tokens,
    extras,
  };
};

const tokenActionsFrom = ({
  token,
  upgradableOffers,
  currentGlobalUserId,
  assignment,
  isOwner,
  related,
  isResaleOpen,
  isDoorsOpen,
  accessControlSubMode,
}: {
  isOwner: boolean;
  token: TokenInfoResponse;
  related: TokenInfoResponse[];
  assignment: TokenAssignment;
  isResaleOpen: boolean;
  currentGlobalUserId: string;
  upgradableOffers: Set<number>;
  accessControlSubMode: AccessControlSubMode;
  isDoorsOpen: boolean;
}): TokenActions => {
  const canUpgrade =
    !isNilOrEmpty(token.offerResponse) &&
    isNilOrEmpty(token.parentInventoryItemId) &&
    upgradableOffers.has(parseInt(token.offerResponse.id));
  const canOpenAccessControl =
    token.selfAssigned && accessControlSubMode === AccessControlSubMode.QR_CODE && isDoorsOpen;
  if (token.accessed) {
    return {
      canReturn: false,
      canEditGuest: false,
      canAssign: false,
      canResendInvite: false,
      canRevoke: false,
      canSeeSellingListing: false,
      canSelfAssign: false,
      canSell: false,
      canUpgrade,
      canOpenAccessControl,
    };
  }
  if (!isNil(token.sellingOfferGroupId) && isResaleOpen) {
    //Token is being sold.
    return {
      canReturn: false,
      canEditGuest: false,
      canAssign: false,
      canResendInvite: false,
      canRevoke: false,
      canSeeSellingListing: true,
      canSelfAssign: false,
      canSell: false,
      canUpgrade,
      canOpenAccessControl: false,
    };
  }
  const isAssignedToCurrentUser = assignment.assignee.globalUserId === currentGlobalUserId;
  const canEditGuest =
    (assignment.accessControlInfo?.type === AccessControlAssigneeType.Guest ||
      assignment.accessControlInfo?.type === AccessControlAssigneeType.GuestChild) &&
    isAssignedToCurrentUser;

  const isUserAccessControlToken =
    isAssignedToCurrentUser &&
    assignment.accessControlInfo?.type === AccessControlAssigneeType.User;
  const canAssign =
    token.categoryDetail?.tokenAssignmentAllowed &&
    (isNil(token.finalTokenAssignment?.assignmentStatus) || isUserAccessControlToken);
  return {
    canReturn: token.selfAssigned && !isOwner,
    canEditGuest,
    canAssign: canAssign && (token.finalTokenAssignment?.numberOfAssignments ?? 0) < 2,
    canResendInvite: assignment.assignee.pendingAccept,
    canRevoke:
      !isAssignedToCurrentUser ||
      (assignment.accessControlInfo &&
        assignment.accessControlInfo?.type !== AccessControlAssigneeType.User),
    canSeeSellingListing: false,
    //canMakeMine on consumer has extra logic around token being identical to user's current token.
    // We removed that here on purpose given that's the current server logic, and possible issues with server vs client 'identity' logic
    canSelfAssign: !token.selfAssigned && isNil(token.finalTokenAssignment?.assignmentStatus),
    canSell:
      isResaleOpen &&
      isOwner &&
      isNil(token.resaleForbiddenContext) &&
      !some(related, ({ resaleForbiddenContext }) => !isNil(resaleForbiddenContext)),
    canUpgrade,
    canOpenAccessControl,
  };
};

const deliveryMethodFrom = (method: DeliveryMethod) => {
  switch (method) {
    case DeliveryMethod.SHIPPING:
      return ExtraDeliveryMethod.Shipping;
    case DeliveryMethod.PICKUP:
      return ExtraDeliveryMethod.PickUp;
    default:
      return ExtraDeliveryMethod.Digital;
  }
};

export const tokenChoiceFrom = (choiceSet: SelectedInventoryChoiceResponse): TokenChoice => ({
  name: choiceSet.name,
  selection: choiceSet.selectedChoice.name,
});

const lastAssignmentActionFrom = (action: ServerTokenAssignmentAction): TokenAssignmentAction => {
  switch (action) {
    case ServerTokenAssignmentAction.CREATE:
      return TokenAssignmentAction.Created;
    case ServerTokenAssignmentAction.ASSIGNEE_ACCEPT:
      return TokenAssignmentAction.Accepted;
    case ServerTokenAssignmentAction.ASSIGNEE_DECLINE:
      return TokenAssignmentAction.Declined;
    case ServerTokenAssignmentAction.ASSIGNMENT_BOUNCE:
      return TokenAssignmentAction.Bounced;
    case ServerTokenAssignmentAction.ASSIGNEE_RETURN:
      return TokenAssignmentAction.Returned;
    case ServerTokenAssignmentAction.ASSIGNER_REVOKE:
      return TokenAssignmentAction.Revoked;
  }
};

//Duplicated from libs/util-token/src/lib/helper/get-token-type.ts
export const getTokenType = (choiceSets: SelectedInventoryChoiceResponse[]): TokenType => {
  const selectedType = choiceSets
    ?.find((_choiceSet) => _choiceSet.choiceTagTypes?.includes(ChoiceTagType.AGE_BASED))
    ?.selectedChoice?.tags?.find((_tag) => _tag.type === ChoiceTagType.AGE_BASED)?.value;
  if (isNil(selectedType)) {
    return undefined;
  }
  switch (selectedType) {
    case ChoiceTagValue.SENIOR:
      return TokenType.Senior;
    case ChoiceTagValue.CHILD:
      return TokenType.Child;
    default:
      return TokenType.Adult;
  }
};

export const pendingTokensFrom = (marketplaces: SimpleMarketplaceResponse[]): PendingToken[] => {
  const tokens: PendingToken[] = [];
  marketplaces?.forEach(({ offerGroupInformation: _offerGroupInformation }) => {
    _offerGroupInformation?.forEach(({ activeOffer }) => {
      if (activeOffer?.offerStatus === OfferStatus.WON_PAYMENT_SUCCESSFUL) {
        const endTime = datetime.parse(activeOffer.inventorySelectionEndTime);
        tokens.push({
          numberOfTokens: activeOffer.numberOfTokens,
          seatSelectionPending: endTime?.isValid() && !datetime.isPast(endTime),
          selectionEndTime: activeOffer.inventorySelectionEndTime,
          selectionStartTime: activeOffer.inventorySelectionStartTime,
        });
      }
    });
  });
  return tokens;
};
