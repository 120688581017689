export enum UiUserActivityTabType {
  LIVE_ATTENDANCE_EVENTS = 'LIVE_ATTENDANCE_EVENTS',
  STREAMING_EVENTS = 'STREAMING_EVENTS',
  ACTIVE_OFFER_EVENTS = 'ACTIVE_OFFER_EVENTS',
  SELLING_EVENT = 'SELLING_EVENT',
  PROMOTER_MANAGEMENT_EVENT = 'PROMOTER_MANAGEMENT_EVENT',
  USER_INBOX = 'USER_INBOX',
  SEASON_MEMBERSHIPS = 'SEASON_MEMBERSHIPS',
}

export interface UiUserActivityTabConfigurationResponse {
  tabResponses: UIUserActivityTabConfigurationResponseItem[];
}

export interface UIUserActivityTabConfigurationResponseItem {
  default: boolean;
  showPastEvents: boolean;
  tabType: UiUserActivityTabType;
}
