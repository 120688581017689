export enum BidExpiration {
  BEFORE_EVENT_2H = 'BEFORE_EVENT_2H',
  BEFORE_EVENT_24H = 'BEFORE_EVENT_24H',
  BEFORE_EVENT_48H = 'BEFORE_EVENT_48H',
  BEFORE_EVENT_72H = 'BEFORE_EVENT_72H',
}

export enum BidStatus {
  OPEN = 'OPEN',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  FILLED = 'FILLED',
  EXPIRED = 'EXPIRED',
}

export enum BidFillType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export interface GetBidResponse {
  id?: number;
  offerId?: number;
  userId?: number;
  eventId?: number;
  inventoryCategoryId?: number;
  inventoryCategoryName?: string;
  price?: number;
  status?: BidStatus;
  statusTime?: string;
  quantity?: number;
  paymentMethodId?: number;
  allowPartialFills?: boolean;
  expireTime?: string;
  cancelTime?: string;
  fillType?: BidFillType;
  customerOrderId?: number;
  expirationType?: BidExpiration;
  expirationOptionCode?: string;
}
