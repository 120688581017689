export enum TokenStatus {
  UNASSIGNED = 'UNASSIGNED',
  SELF_ASSIGNED_BUYER = 'SELF_ASSIGNED_BUYER',
  SELF_ASSIGNED_ASSIGNEE = 'SELF_ASSIGNED_ASSIGNEE',
  ASSIGNED_TO_GUEST = 'ASSIGNED_TO_GUEST',
  CHAIN_ASSIGNED_TO_GUEST = 'CHAIN_ASSIGNED_TO_GUEST',
  ASSIGNED_TO_YOUTH = 'ASSIGNED_TO_YOUTH',
  CHAIN_ASSIGNED_TO_YOUTH = 'CHAIN_ASSIGNED_TO_YOUTH',
  ASSIGNMENT_PENDING = 'ASSIGNMENT_PENDING',
  CHAIN_ASSIGNMENT_PENDING = 'CHAIN_ASSIGNMENT_PENDING',
  ASSIGNED_THROUGH_EMAIL = 'ASSIGNED_THROUGH_EMAIL',
  CHAIN_ASSIGNED_THROUGH_EMAIL = 'CHAIN_ASSIGNED_THROUGH_EMAIL',
  IS_BEING_SOLD = 'IS_BEING_SOLD',
}

export enum TokenType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  SENIOR = 'SENIOR',
}
