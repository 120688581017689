import { ClientError } from './client-error';
import type { ClientErrorWithResponse } from './client-error-with-response';

/**
 * Represents an `ApiSauce` `SERVER_ERROR` problem.
 */
export class ClientServerError<T> extends ClientError implements ClientErrorWithResponse<T> {
  public statusCode: number;
  public response: T;

  constructor(statusCode: number, response: T) {
    super(`The client request failed with status code '${statusCode}'`);
    Object.setPrototypeOf(this, ClientServerError.prototype);

    this.statusCode = statusCode;
    this.response = response;
  }
}
