import type { AccessibilityType } from '@yoop/server-model/event-accessibility';

export interface LocationFilter {
  cityId: string;
  latitude: number;
  longitude: number;
  radiusInMeters: number;
}

export enum EventTagType {
  CLIENT = 'CLIENT',
  LOCATION_PROFILE = 'LOCATION_PROFILE',
  PERFORMER_PROFILE = 'PERFORMER_PROFILE',
}

export interface EventTagFilter {
  clientIds?: number[];
  locationIds?: number[];
  performerIds?: number[];
  primaryTagType: EventTagType;
}

export interface EventFilter {
  eventAccessibilityTypes?: AccessibilityType[];
  eventIdFilter?: number[];
  eventTagFilter?: EventTagFilter;
  includeUpcomingEvents?: boolean;
  locationFilter?: LocationFilter;
}
