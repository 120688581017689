import { communicationStore } from '@yoop/message-channel';
import { apiClientStore } from '@yoop/whitelabel-api-client';
import { CommsMessageActions } from '@yoop/whitelabel-data';

export const onRevokeToken = async (tokenId: number) => {
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.ResaleEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    await apiClient.tokenController.revokeToken(tokenId);
  });
};

export const onResendTokenInvite = async (tokenId: number) => {
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.ResaleEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    await apiClient.tokenController.resendInvite(tokenId);
  });
};

export const onReturnToken = async (tokenId: number) => {
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.ResaleEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    await apiClient.tokenController.returnToken(tokenId);
  });
};

export const onAssignTokenToCurrentUser = async (eventId: number, tokenId: number) => {
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.ResaleEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    await apiClient.tokenController.selfAssignToken(eventId, tokenId);
  });
};
