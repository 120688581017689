import { getUserLocale } from '@yoop/service-i18n';
import formatter from 'format-number';
import { isNilOrZero } from './is-nil-or-zero';
import isNil from 'ramda/src/isNil';
import { Currency } from '@yoop/server-model/payment';

export const MAX_SIGNED_32_INTEGER = 2147483647;

// TODO move these functions outside of numbers in their own file
export const numbers = {
  format: (value: number, zeroAsHyphen = false): string => {
    const defaultFormatter = formatter({ round: 0 });
    if (zeroAsHyphen && value === 0) {
      return '-';
    }
    return isNil(value) ? '-' : defaultFormatter(value);
  },
  money: (
    value: number | string,
    currency: Currency,
    forceDecimals = false,
    zeroAsHyphen = false,
    prefix = '',
  ): string => {
    const numberToFormat = typeof value === 'string' ? Number(value) : value;
    const userLocale = getUserLocale() ?? 'en-US';

    if (zeroAsHyphen && isNilOrZero(numberToFormat)) {
      return '-';
    }

    const formattedCurrency = new Intl.NumberFormat(userLocale, {
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: currency ? currency : Currency.USD,
    }).format(numberToFormat);
    return (
      prefix + (forceDecimals ? formattedCurrency : formattedCurrency.replace(/\D00(?=\D*$)/, ''))
    );
  },
  percent: (value: number, zeroAsHyphen = false, maximumFractionDigits = 0): string => {
    if (zeroAsHyphen && isNilOrZero(value)) {
      return '-';
    }
    return formatter({ round: maximumFractionDigits, integerSeparator: '', suffix: '%' })(value);
  },
};
