import type { UserProfile } from '@yoop/whitelabel-data';
import type { StateCreator } from 'zustand/vanilla';
import { createStore } from 'zustand/vanilla';

interface UserSlice {
  currentUser: UserProfile;
  updateCurrentUser: (user: UserProfile) => void;
}

const createUserSlice: StateCreator<UserSlice> = (set) => ({
  currentUser: undefined,
  updateCurrentUser: (newUser: UserProfile) => set({ currentUser: newUser }),
});

interface ConfigSlice {
  clientId: number;
  updateClient: (id: number) => void;
}

const createConfigSlice: StateCreator<ConfigSlice> = (set) => ({
  clientId: undefined,
  updateClient: (id) => set({ clientId: id }),
});

export const appStore = createStore<UserSlice & ConfigSlice>((...params) => ({
  ...createUserSlice(...params),
  ...createConfigSlice(...params),
}));
