export enum MarketplaceState {
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  WINNERS_SELECTED = 'WINNERS_SELECTED',
  ALLOCATE_READY = 'ALLOCATE_READY',
}

export enum MarketplaceType {
  DRAW = 'DRAW',
  AUCTION = 'AUCTION',
  PURCHASE = 'PURCHASE',
  SECONDARY_EXCHANGE = 'SECONDARY_EXCHANGE',
  PROMOTER = 'PROMOTER',
  CLIENT_GUEST = 'CLIENT_GUEST',
}
