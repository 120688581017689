import { ApiController } from '@yoop/base-client';
//FIXME: this prevents this lib from being bundable, but we'll likely not use the carousel list endpoint given it's not for a specific client.
// import type { EventCarouselListResponse } from '@yoop/data-access-model';
import type { AccessibilityType } from '@yoop/server-model/event-accessibility';
import type {
  EventFilter,
  UserActivityEventListResponse,
  UserActivityListSummaryResponse,
  DateFilterType,
  SecondaryListingsResponse,
  EventTagInfoResponse,
  UserActivityEventDetailResponse,
  GetOffersUpgradabilityRequest,
  OffersUpgradabilityResponse,
} from '@yoop/server-model/user-activity';

export class WhitelabelEventController extends ApiController {
  public getCarouselListEvents(): Promise<any> {
    return this.get('/event/public/carouselList');
  }

  public getUserActivityListSummary(clientId?: number): Promise<UserActivityListSummaryResponse> {
    return this.get('/user/activityList/summary', { clientId });
  }

  public getUserEvents({
    clientId,
    type,
    dateFilterType,
  }: {
    clientId: number;
    type: AccessibilityType;
    dateFilterType?: DateFilterType;
  }): Promise<UserActivityEventListResponse> {
    return this.get('/user/activityList/attendingEventList', {
      clientId,
      dateFilterType,
      categoryAccessibilityType: type,
    });
  }

  public getUserRequestedEvents({
    clientId,
    dateFilterType,
  }: {
    clientId: number;
    dateFilterType?: DateFilterType;
  }): Promise<UserActivityEventListResponse> {
    return this.get('/user/activityList/activeOfferEventList', { clientId, dateFilterType });
  }

  public getUserSellingEvents({
    clientId,
    dateFilterType,
  }: {
    clientId: number;
    dateFilterType?: DateFilterType;
  }): Promise<SecondaryListingsResponse> {
    return this.get('/secondaryListings', { clientId, dateFilterType });
  }

  public getUpcomingEventsWithTags(eventFilter: EventFilter): Promise<EventTagInfoResponse> {
    return this.put('/event/public/profileList/tag', eventFilter);
  }

  public getUserActivityDetails(
    eventId: string,
    marketplaceCode?: string,
  ): Promise<UserActivityEventDetailResponse> {
    return this.get('/user/activity/v2', { eventId, marketplaceCode });
  }

  public getOffersUpgradability(
    request: GetOffersUpgradabilityRequest,
  ): Promise<OffersUpgradabilityResponse> {
    return this.post('/offer/offers/upgradable', request);
  }
}
