import type { AccessibilityType } from '@yoop/server-model/event-accessibility';
import type { MediaResponse } from '@yoop/server-model/media';
import type {
  ChoiceTagType,
  GetInventoryChoiceResponse,
  InventoryChoiceSetTranslation,
} from './choice';
import type { InventoryCategoryUIDisplaySettings } from './inventory-category-ui-display-settings';

export interface SelectedInventoryChoiceResponse {
  id: number;
  choiceSetId: number;
  code?: string;
  ranking?: number;
  description?: string;
  choices: GetInventoryChoiceResponse[];
  choiceTagTypes?: ChoiceTagType[];
  name: string;
  defaultChoice?: GetInventoryChoiceResponse;
  selectedChoice?: GetInventoryChoiceResponse;
  translations?: InventoryChoiceSetTranslation[];
}

export enum CategoryType {
  EVENT_ACCESS = 'EVENT_ACCESS',
  MERCHANDISE = 'MERCHANDISE',
  ADD_ON = 'ADD_ON',
  CHARITY = 'CHARITY',
  COLLECTIBLE = 'COLLECTIBLE',
}

export enum DeliveryMethod {
  SHIPPING = 'SHIPPING',
  PICKUP = 'PICKUP',
  DIGITAL = 'DIGITAL',
}

export enum CategoryDetailGroupingType {
  BUNDLE = 'BUNDLE',
  GROUP = 'GROUP',
  HOTEL_ROOM = 'HOTEL_ROOM',
  TABLE = 'TABLE',
}
export interface CategoryDetailChildGroupingResponse {
  type: CategoryDetailGroupingType;
}

export interface CategoryDetailResponse {
  id: number;

  eventId?: number;

  name?: string;

  accessibilityType: AccessibilityType;

  childGroupingResponse?: CategoryDetailChildGroupingResponse;
  /* Number of Yoop (tokens) this offer group contains */
  accessTokensPerInventory?: number;

  choiceSets?: SelectedInventoryChoiceResponse[];

  detail?: string;

  /* The maximum date a user can be born in to be allowed in this event */
  maxUserDateOfBirth?: string;

  merchandiseDescription?: string;

  merchandiseMedia?: MediaResponse[];

  merchandiseDeliveryMethod?: DeliveryMethod;

  shippingAddressRequired?: boolean;

  resaleAllowed: boolean;

  type?: CategoryType;

  ticketLimit?: number;

  tokenAssignmentAllowed?: boolean;

  displaySettings?: InventoryCategoryUIDisplaySettings;

  code?: string;
}

export interface CategoryGroupingInfo {
  categoryType: CategoryType | undefined;
  childGroupingType: CategoryDetailGroupingType | undefined;
  hasMultipleAccessPerInventory: boolean;
}
