export enum MyYoopTabType {
  InPerson = 'inPerson',
  Virtual = 'virtual',
  Requested = 'requested',
  Selling = 'selling',
  Membership = 'membership',
}

export interface MyYoopTab {
  type: MyYoopTabType;
  hasPastEvents: boolean;
  isDefault: boolean;
}
