import type { MediaResponse as YoopMediaResponse } from '@yoop/server-model/media';

export const mediaFrom = (media: YoopMediaResponse) => ({
  id: media.id,
  height: media.height,
  width: media.width,
  url: media.url,
  shortDescription: media.shortDescription,
  longDescription: media.longDescription,
});
