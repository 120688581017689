import type { ApiControllerConfig } from './api-controller';
import type { ApisauceConfig, ApisauceInstance } from 'apisauce';
import { create } from 'apisauce';
import { DEFAULT_CONFIG } from './default-config';
import merge from 'ramda/src/merge';

export type ClientConfig = ApisauceConfig & ApiControllerConfig;
/**
 * This client is made to be composed with multiple controllers.
 */
export class BaseClient<T extends ClientConfig = ClientConfig> {
  protected api: ApisauceInstance;
  protected config: T;

  constructor(config: T) {
    const combinedConfig = merge(DEFAULT_CONFIG, config) as T;
    this.api = create(combinedConfig);
    this.config = combinedConfig;
  }

  protected setHeader(header: string, value: string): void {
    this.api.setHeader(header, value);
  }

  public setLanguageHeader(locale: string): void {
    this.setHeader('Accept-Language', locale);
  }
}
