export interface EventData {
  id: number;
  name: string;
  vanityName: string;
  isSoldOut: boolean;
  startDate: string;
  endDate: string;
  eventAccessibilityType: AccessibilityType;
  locationName: string;
  //true if the user already have a Yoop
  hasYoop: boolean;
  //true if the user requested a Yoop through MyPrice, Wishlist or MyPriceOffers
  didRequestYoop: boolean;
  //true if the user can purchase more Yoop
  canGetMoreYoop: boolean;
  //Status for MyPriceOffers
  exchangeStatus: ExchangeStatus;
  //Min price the user can purchase or request an Yoop for
  minPrice: string;
  //Images for that event
  media: MediaResponse[];
  status: EventStatus;
  //Currency abbreviation (USD, CAD, EUR, ...)
  currency: string;
  activeMarketplace?: ActiveMarketplace;
  announcement?: string;
}

export interface ActiveMarketplace {
  type: MarketplaceType;
  numberOfListings: number;
}

export enum ExchangeStatus {
  Disabled = 'DISABLED',
  Offer = 'OFFER',
  Request = 'REQUEST',
}

export enum AccessibilityType {
  LiveAttendance = 'LIVE_ATTENDANCE',
  Streaming = 'STREAMING',
}

export enum EventStatus {
  New = 'NEW',
  Requested = 'REQUESTED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Upcoming = 'UPCOMING',
  SoldOut = 'SOLD_OUT',
}

export interface MediaResponse {
  id: string;
  url: string;
  shortDescription?: string;
  longDescription?: string;
  width?: number;
  height?: number;
}

export enum MarketplaceType {
  Wishlist = 'WISHLIST',
  MyPrice = 'MY_PRICE',
  DirectPurchase = 'DIRECT_PURCHASE',
  Resale = 'RESALE',
}
