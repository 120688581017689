import { communicationStore } from '@yoop/message-channel';
import type { EventListResponseItem } from '@yoop/server-model/user-activity';
import { EventTagType } from '@yoop/server-model/user-activity';
import { apiClientStore } from '@yoop/whitelabel-api-client';
import { CommsMessageActions } from '@yoop/whitelabel-data';
import { appStore } from '@yoop/whitelabel-manager-store';
import type { YoopUserActivityListSummaryResponseItem } from './event.helper';
import { mapEventsData } from './event.helper';

export const onGetEventsData = async () => {
  const { clientId, currentUser } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  const { apiClient } = apiClientStore.getState();

  sendDataMessage(CommsMessageActions.GetEvents, async () => {
    const [eventsResults, userEventsResult] = await Promise.allSettled([
      apiClient.eventController.getUpcomingEventsWithTags({
        eventTagFilter: {
          clientIds: [clientId],
          primaryTagType: EventTagType.CLIENT,
        },
      }),
      currentUser ? apiClient.eventController.getUserActivityListSummary(clientId) : undefined,
    ]);
    let events: EventListResponseItem[] = [];
    if (eventsResults.status === 'fulfilled') {
      events = eventsResults.value.eventList;
    } else {
      throw eventsResults.reason;
    }
    let userEvents: YoopUserActivityListSummaryResponseItem[] = [];
    if (userEventsResult?.status === 'fulfilled') {
      userEvents = userEventsResult.value?.userActivityList ?? [];
    }

    return mapEventsData(events, userEvents);
  });
};
