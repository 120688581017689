import { isNilOrEmpty } from '@yoop/util';
import { browserVersionOlderThan, osVersionOlderThan } from '@yoop/util-version';
import type { Dayjs, OptionType, UnitType } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/es';
import 'dayjs/locale/es-us';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { Duration, DurationUnitType } from 'dayjs/plugin/duration';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import isNil from 'ramda/src/isNil';
import { isIOS, isSafari } from 'react-device-detect';
import { removeMs } from './remove-ms';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

//TODO: Move this to a browser util lib
// Safari 13 and older, and any browser on iOS 13 and older, can't parse correctly if format isn't specified
const forceSpecifyFormat = (): boolean => {
  return (isSafari && browserVersionOlderThan(14)) || (isIOS && osVersionOlderThan(14));
};

const diff = (
  date: Dayjs,
  otherDate: Dayjs,
  unit: UnitType = 'second',
  precise?: boolean,
): number => {
  removeMs(unit, date, otherDate);
  return date.diff(otherDate, unit, precise);
};

/**
 * DayJS has a bug changing timezones when the browser and the date aren't in the same DST state.
 *  - https://github.com/iamkun/dayjs/issues/1805
 *  - The error occurs if the browser is not in DST and the time object is.
 *  - Each time .tz is called, the time object loses an hour.
 *  - By calling it twice we can measure the offset.
 * This function checks for the situation and corrects the error in the output.
 * Use this instead of the .tz() method of DayJS objects.
 */
interface TimezoneOptions {
  keep?: boolean; //Change timezone without changing time
}

export const ApiDateTimeFormat = {
  dateOfBirth: 'YYYY-MM-DD',
  server: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  serverLegacy: "YYYY-MM-DD'T'HH:mm:ssX",
};

const changeTimezone = (date: Dayjs, timezone: string, options?: TimezoneOptions) => {
  if (isNilOrEmpty(date) || isNilOrEmpty(timezone)) {
    return date;
  }
  return date.tz(timezone, options?.keep ?? false);
};

// TODO extract these function from datetime and move them to their own file
export const datetime = {
  parse: (value: string, format?: OptionType, strict = false): Dayjs => {
    if (isNil(value)) {
      return null;
    }

    // Server response has different date formats. Using second one as a fallback until the response is unified.
    const date = dayjs(
      value,
      isNil(format) && forceSpecifyFormat()
        ? [ApiDateTimeFormat.server, ApiDateTimeFormat.serverLegacy]
        : format,
      strict,
    );
    if (!date.isValid()) {
      return null;
    }
    return date;
  },
  isLive: (startDate: Dayjs, endDate: Dayjs, granularity: UnitType = 'second'): boolean => {
    if (isNil(startDate) || isNil(endDate)) {
      return false;
    }
    removeMs(granularity, startDate, endDate);
    return dayjs().isBetween(startDate, endDate, granularity, '[]');
  },
  isUpcoming: (date: Dayjs, granularity: UnitType = 'second'): boolean => {
    if (isNil(date)) {
      return false;
    }
    removeMs(granularity, date);
    return dayjs().isBefore(date, granularity);
  },
  isUpcomingOrNow: (date: Dayjs, granularity: UnitType = 'second'): boolean => {
    if (isNil(date)) {
      return false;
    }
    removeMs(granularity, date);
    return dayjs().isSameOrBefore(date, granularity);
  },
  isPast: (date: Dayjs, granularity: UnitType = 'second'): boolean => {
    if (isNil(date)) {
      return false;
    }
    removeMs(granularity, date);
    return dayjs().isAfter(date, granularity);
  },
  isPastOrNow: (date: Dayjs, granularity: UnitType = 'second'): boolean => {
    if (isNil(date)) {
      return false;
    }
    removeMs(granularity, date);
    return dayjs().isSameOrAfter(date, granularity);
  },
  now: (): Dayjs => dayjs(),
  unix: () => dayjs().unix(),
  isToday: (date: Dayjs, granularity: UnitType = 'day'): boolean => {
    if (isNil(date)) {
      return false;
    }
    removeMs(granularity, date);
    return date.isSame(datetime.now(), granularity);
  },
  isSameDay: (date: Dayjs, otherDate: Dayjs, granularity: UnitType = 'day'): boolean => {
    if (isNil(date) || isNil(otherDate)) {
      return false;
    }
    removeMs(granularity, date, otherDate);
    return date.isSame(otherDate, granularity);
  },
  isSameMonth: (date: Dayjs, otherDate: Dayjs, granularity: UnitType = 'month'): boolean => {
    if (isNil(date) || isNil(otherDate)) {
      return false;
    }
    removeMs(granularity, date, otherDate);
    return date.isSame(otherDate, granularity);
  },
  max: (date: Dayjs, otherDate: Dayjs, granularity: UnitType = 'second'): Dayjs => {
    if (isNil(date) || isNil(otherDate)) {
      return null;
    }
    removeMs(granularity, date, otherDate);
    return date.isAfter(otherDate) ? date : otherDate;
  },
  min: (date: Dayjs, otherDate: Dayjs, granularity: UnitType = 'second'): Dayjs => {
    if (isNil(date) || isNil(otherDate)) {
      return null;
    }
    removeMs(granularity, date, otherDate);
    return date.isBefore(otherDate) ? date : otherDate;
  },
  maxString: (
    dateString: string,
    otherDateString: string,
    granularity: UnitType = 'second',
  ): string => {
    const date = datetime.parse(dateString);
    const otherDate = datetime.parse(otherDateString);
    if (isNil(date) || isNil(otherDate)) {
      return null;
    }
    removeMs(granularity, date, otherDate);
    return date.isAfter(otherDate) ? dateString : otherDateString;
  },
  minString: (
    dateString: string,
    otherDateString: string,
    granularity: UnitType = 'second',
  ): string => {
    const date = datetime.parse(dateString);
    const otherDate = datetime.parse(otherDateString);
    if (isNil(date) || isNil(otherDate)) {
      return null;
    }
    removeMs(granularity, date, otherDate);
    return date.isBefore(otherDate) ? dateString : otherDateString;
  },
  getTimeLeft: (date: Dayjs, unit: UnitType = 'second'): number => {
    const now = datetime.now();
    removeMs(unit, now, date);
    return date.diff(now, unit);
  },
  getTimeLeftRelativeToMidnight: (date: Dayjs, unit: UnitType = 'second'): number => {
    const midNightToday = datetime.now().hour(0).minute(0).second(0).millisecond(0);
    return date.diff(midNightToday, unit);
  },
  duration: (input: number, unit?: DurationUnitType): Duration => dayjs.duration(input, unit),
  stringifyDateRange: (date1: Dayjs, date2: Dayjs): string => {
    if (isNilOrEmpty(date1)) {
      return undefined;
    }
    if (isNilOrEmpty(date2)) {
      return date1.format('MMM D');
    }
    if (date1.isSame(date2, 'day')) {
      return date1.format('MMM D');
    }
    if (date1.month() === date2.month()) {
      return `${date1.format('MMM D')} - ${date2.format('D')}`;
    }
    return `${date1.format('MMM D')} - ${date2.format('MMM D')}`;
  },
  mergeDayjsDateAndTime: (date: Dayjs, time: Dayjs): Dayjs | undefined => {
    if (isNil(date) || isNil(time)) {
      return undefined;
    }
    return date.set('hour', time.hour()).set('minute', time.minute()).set('second', time.second());
  },
  mergeDateAndTime: (dateInSeconds: number, timeInSeconds: number): Dayjs | undefined => {
    if (isNil(dateInSeconds) || isNil(timeInSeconds)) {
      return undefined;
    }
    const date = dayjs.unix(dateInSeconds);
    const time = dayjs.unix(timeInSeconds);
    return datetime.mergeDayjsDateAndTime(date, time);
  },
  timeInSecondsToDate: (timeInSeconds: number): Dayjs | undefined => {
    if (isNil(timeInSeconds)) {
      return undefined;
    }
    return dayjs.unix(timeInSeconds);
  },
  getTimeInMinutes: (date: Dayjs): number => date.hour() * 60 + date.minute(),
  startOfDay: (date: Dayjs): Dayjs => date.hour(0).minute(0).second(0).millisecond(0),
  endOfDay: (date: Dayjs): Dayjs => date.hour(23).minute(59).second(59).millisecond(999),
  browserTimezone: () => dayjs.tz.guess(),
  changeTimezone,
  diff,
};
