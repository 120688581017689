import type { SimpleOfferResponse } from '@yoop/server-model/token-info';
import { OfferStatus } from '@yoop/server-model/token-info';

/**
 * Predicate for pending result offers (after marketplace has ended)
 * @param offer
 */
export const pendingResult: (offer: SimpleOfferResponse) => boolean = (
  offer: SimpleOfferResponse,
): boolean =>
  offer &&
  (offer.offerStatus === OfferStatus.DEFAULT ||
    offer.offerStatus === OfferStatus.WON_PAYMENT_PROCESSING_PENDING);
