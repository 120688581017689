import { BaseClient, type ClientConfig } from '@yoop/base-client';
import { WhitelabelConfigController } from '@yoop/client-config-controller';
import { WhitelabelEventController } from '@yoop/event-controller';
import { UserController } from '@yoop/user-controller';
import { createStore } from 'zustand/vanilla';
import { EventTokenController } from '@yoop/event-controller';

export class WhitelabelApiClient extends BaseClient {
  userController: UserController;
  eventController: WhitelabelEventController;
  tokenController: EventTokenController;
  clientConfigController: WhitelabelConfigController;

  constructor(config: ClientConfig) {
    super(config);
    this.userController = new UserController(this.api, config);
    this.eventController = new WhitelabelEventController(this.api, config);
    this.tokenController = new EventTokenController(this.api, config);
    this.clientConfigController = new WhitelabelConfigController(this.api, config);
  }
}

interface ApiStore {
  apiClient: WhitelabelApiClient;
  initializeClient: (config: ClientConfig) => WhitelabelApiClient;
}

export const apiClientStore = createStore<ApiStore>((setState) => ({
  apiClient: undefined,
  initializeClient: (config) => {
    const client = new WhitelabelApiClient(config);
    setState({
      apiClient: client,
    });
    return client;
  },
}));
