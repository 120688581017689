import type { PaymentMeans } from '@yoop/server-model/payment';
import type { TokenPrice } from '../token-price';

export enum Chance {
  GREAT = 'GREAT',
  GOOD = 'GOOD',
  OK = 'OK',
  POOR = 'POOR',
  NEGLIGIBLE = 'NEGLIGIBLE',
}

export interface OfferShippingAddressResponse {
  countryCode: string;
  freeFormAddress: string;
  fullName: string;
  googlePlaceId: string;
  unitInformation: string;
}

export enum OfferStatus {
  DEFAULT = 'DEFAULT',
  WON_PAYMENT_PROCESSING_PENDING = 'WON_PAYMENT_PROCESSING_PENDING',
  WON_MANUAL_PAYMENT_REQUIRED = 'WON_MANUAL_PAYMENT_REQUIRED',
  WON_PAYMENT_SUCCESSFUL = 'WON_PAYMENT_SUCCESSFUL',
  WON_TOKEN_ASSIGNED = 'WON_TOKEN_ASSIGNED',
  LOST = 'LOST',
}

export enum OfferSubStatus {
  PAYMENT_OPTION_DECLINED = 'PAYMENT_OPTION_DECLINED',
  PAYMENT_FAILED_RETRY_ATTEMPTS_EXCEEDED = 'PAYMENT_FAILED_RETRY_ATTEMPTS_EXCEEDED',
  PAYMENT_FAILED_TIME_EXPIRED = 'PAYMENT_FAILED_TIME_EXPIRED',
  PAYMENT_CLAIM_TIME_EXPIRED = 'PAYMENT_CLAIM_TIME_EXPIRED',
  NOT_SELECTED = 'NOT_SELECTED',
  AUTO_PAYMENT_FAILED = 'AUTO_PAYMENT_FAILED',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
}

export interface SimpleOfferResponse {
  /* The ID of the offer */
  id: string;

  tokenPrice: TokenPrice;

  /* The amount per token for this offer */
  amount?: number;

  /* is auto-pay enabled? */
  autoPayEnabled?: boolean;

  /* If this entry is for market pricing, this is the chance this entry has for obtaining access */
  chance?: Chance;

  /*  Has this offer been archived for display purposes? */
  displayArchive?: boolean;

  /* A non-null value indicates if the offer can select seats until the specified time */
  inventorySelectionEndTime?: string;

  /* A non-null value indicates if the offer can select seats at the specified time */
  inventorySelectionStartTime?: string;

  /* The number of tokens requested with this offer */
  numberOfTokens?: number;

  /* The category name */
  offerGroupDescription?: string;

  /* The ID of the offer group */
  offerGroupId?: string;

  /* The status of the offer */
  offerStatus?: OfferStatus;

  /* The status of the offer */
  offerSubStatus?: OfferSubStatus;

  /* Payment means that was used for this offer */
  paymentMeans?: PaymentMeans;

  purchaseTimestamp?: string;

  /* The number of retry payment attempts left */
  retryAttemptsLeft?: number;

  /* The end time for retry payment attempts */
  retryEndTime?: string;

  shippingAddress?: OfferShippingAddressResponse;

  inventoryItemIds?: number[];

  transactionId?: number;

  marketplaceId?: number;
  // checkoutTermsAndConditions: CheckoutTermsAndConditions[];

  checkoutResaleText?: string;
}
