import { isEqual } from 'lodash';
import { isNilOrEmpty } from './is-nil-or-empty';

export const isEqualValues = (obj1: any, obj2: any) => {
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1 ?? {});
    const keys2 = Object.keys(obj2 ?? {});
    const checkedKeys = new Set<string>();
    for (const key of keys1) {
      checkedKeys.add(key);
      if (!isEqualValues(obj1[key], obj2[key])) {
        return false;
      }
    }
    for (const key of keys2) {
      if (checkedKeys.has(key)) {
        continue;
      }
      checkedKeys.add(key);
      if (!isEqualValues(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }
  if (isNilOrEmpty(obj1) && isNilOrEmpty(obj2)) {
    return true;
  }
  return isEqual(obj1, obj2);
};
