import type {
  AccessControlIdCheckMode,
  AccessControlMode,
  AccessControlSubMode,
} from '@yoop/server-model/event-access-control';
import type { AccessibilityType } from '@yoop/server-model/event-accessibility';
import type { MediaResponse } from '@yoop/server-model/media';
import type { Currency } from '@yoop/server-model/payment';
import type { TokenInfoResponse } from '@yoop/server-model/token-info';
import type { EventState } from './event-state';
import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import type { GetBidResponse } from './get-bid-response';
import type { UserGetEventExchangeInfoResponse } from './user-get-event-exchange-info-response';

export interface SimpleUserActivityEventResponse {
  addressLine1: string;
  addressLine2: string;
  announcement: string;
  assigneeTokenInformation: TokenInfoResponse[];
  bids?: GetBidResponse[];
  cityName: string;
  countryName: string;
  currency: Currency;
  defaultMedia: MediaResponse;
  doorsCloseDate: string;
  doorsOpenDate: string;
  eventAccessibilityType: AccessibilityType;
  eventDate: string;
  eventEndDate: string;
  eventStartDate: string;
  eventSortDate: string;
  facebookPixelId: string;
  geoRegionAbbreviation: string;
  geoRegionName: string;
  id: string;
  liveStreamEndDate: string;
  liveStreamStartDate: string;
  locationName: string;
  marketplaceInformation: SimpleMarketplaceResponse[];
  maxUserDateOfBirth: string;
  name: string;
  nextMarketplace: SimpleMarketplaceResponse;
  originalEventDate: string;
  postalCode: string;
  refundDeadline: string;
  resaleMarketplace: SimpleMarketplaceResponse;
  seatMap: MediaResponse;
  shortName: string;
  state: EventState;
  timeZoneOffset: string;
  tokenInformation: TokenInfoResponse[];
  userEventKey: string;
  userUUIDPrefix: string;
  usherUUID: string;
  vanityName: string;
  payoutDate?: string;
  accessControlMode: AccessControlMode;
  accessControlSubMode: AccessControlSubMode;
  accessControlIdCheckMode: AccessControlIdCheckMode;
  exchangeInfo?: UserGetEventExchangeInfoResponse;
  seatsIOChartEventKey?: string;
  hasActiveOrFuturePrivateMarketplace: boolean;
  media: MediaResponse[];
  priceDisplay?: PriceDisplayMode;
}

export interface UserActivityEventListResponse {
  userActivityList: SimpleUserActivityEventResponse[];
}

export enum DateFilterType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  CURRENT_AND_FUTURE = 'CURRENT_AND_FUTURE',
  PAST = 'PAST',
}

export enum PriceDisplayMode {
  BASE_PRICE = 'BASE_PRICE',
  ALL_IN_PRICE = 'ALL_IN_PRICE',
}
