export enum AccessControlMode {
  EXTERNAL = 'EXTERNAL',
  YOOP = 'YOOP',
}

export enum AccessControlSubMode {
  BLE = 'BLE',
  NFC_BLE = 'NFC_BLE',
  QR_CODE = 'QR_CODE',
}

export enum AccessControlIdCheckMode {
  NO_ID = 'NO_ID',
  FORCE_ID = 'FORCE_ID',
  TRUE_YOOP = 'TRUE_YOOP',
}

export interface AccessControlScanTimeRangeResponse {
  id: number;
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  inventoryCategoryCodes?: string[];
}

export interface EventAccessControlConfigurationResponse {
  eventId: number;
  accessControlMode: AccessControlMode;
  accessControlSubMode: AccessControlSubMode;
  accessControlIdCheckMode: AccessControlIdCheckMode;
  preferredAccessControlPlatform: PreferredAccessControlPlatform;
  scanTimeRanges: AccessControlScanTimeRangeResponse[];
  isRescanAllowed: boolean;
}

export enum PreferredAccessControlPlatform {
  ALL = 'ALL',
  MOBILE = 'MOBILE',
}

interface AccessControlScanTimeRangeRequest {
  code: string;
  startDate: string;
  endDate: string;
  name: string;
}

export interface EventAccessControlConfigurationRequest {
  eventId?: number;
  accessControlMode?: AccessControlMode;
  accessControlSubMode?: AccessControlSubMode;
  accessControlIdCheckMode?: AccessControlIdCheckMode;
  scanTimeRanges?: AccessControlScanTimeRangeRequest[];
  isRescanAllowed?: boolean;
}

export interface AccessControlScanTimeRangeUpdateRequest {
  eventId: number;
  code: string;
  startDate: string;
  endDate: string;
  name: string;
}

export interface AccessControlScanTimeRangeDeleteRequest {
  eventId: number;
  codes: string[];
}
