import { ApiController } from '@yoop/base-client';
import type { TokenAssignmentResendResponse } from '@yoop/server-model/token-info';

export class EventTokenController extends ApiController {
  public revokeToken(inventoryItemId: number): Promise<Record<string, never>> {
    return this.del('/tokenAssignment/revoke', { inventoryItemId });
  }

  public returnToken(inventoryItemId: number): Promise<Record<string, never>> {
    return this.del('/tokenAssignment/return', { inventoryItemId });
  }

  public selfAssignToken(eventId: number, tokenId: number): Promise<void> {
    return this.put(`/tokenAssignment/selfAssign?eventId=${eventId}&tokenId=${tokenId}`);
  }

  public resendInvite(assignmentGroupId: number): Promise<TokenAssignmentResendResponse> {
    return this.put(`tokenAssignment/resend?assignmentGroupId=${assignmentGroupId}`);
  }
}
