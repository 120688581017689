import type { TranslationHolder } from '@yoop/server-model/translation';

export enum ChoiceTagType {
  AGE_BASED = 'AGE_BASED',
}

export enum ChoiceTagValue {
  CHILD = 'CHILD',
  ADULT = 'ADULT',
  SENIOR = 'SENIOR',
}

export interface ChoiceTag {
  type: ChoiceTagType;
  value: ChoiceTagValue;
  description?: string;
}

export interface InventoryChoiceTranslation extends TranslationHolder {
  name?: string;
}

export interface InventoryChoiceSetTranslation extends TranslationHolder {
  name?: string;
  description?: string;
}

export interface GetInventoryChoiceResponse {
  id: number;
  code?: string;
  name: string;
  ranking?: number;
  priceDifference?: number;
  tags?: ChoiceTag[];
  translations?: InventoryChoiceTranslation[];
  choiceSetId?: number;
  archived?: boolean;
}

export interface ItemOption {
  name: string;
  choiceSetId: number;
  choiceId: number;
  choiceName: string;
  priceDifference?: number;
}
