export enum TokenAssignmentAction {
  CREATE = 'CREATE',
  ASSIGNEE_ACCEPT = 'ASSIGNEE_ACCEPT',
  ASSIGNEE_DECLINE = 'ASSIGNEE_DECLINE',
  ASSIGNEE_RETURN = 'ASSIGNEE_RETURN',
  ASSIGNER_REVOKE = 'ASSIGNER_REVOKE',
  ASSIGNMENT_BOUNCE = 'ASSIGNMENT_BOUNCE',
}

/* The token assignmentState status of the token assignmentState */
export enum AssignmentStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  GUEST = 'GUEST',
  GUEST_CHILD = 'GUEST_CHILD',
}
