import { isNilOrEmpty } from '@yoop/util';
import type { DateOfBirth } from './models/user';

export const dateOfBirthFrom = (serverDate: string): DateOfBirth => {
  if (isNilOrEmpty(serverDate)) {
    return undefined;
  }
  const [year, month] = serverDate.split('-');
  return { year: parseInt(year), month: parseInt(month) };
};
