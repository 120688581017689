import type { SimpleMarketplaceResponse } from '@yoop/data-access-model';
import { containsUnsecuredOffer } from '@yoop/util-offer/helper';

/**
 * Checks if a group of marketplaces contains at least one marketplace with at least one unsecured offer
 * @param marketplaces
 * @param notLost
 */
export const containsUnsecuredOfferGroup: (
  marketplaces: SimpleMarketplaceResponse[],
  notLost: boolean,
) => boolean = (marketplaces: SimpleMarketplaceResponse[], notLost: boolean) =>
  marketplaces.some(
    (marketplace) =>
      marketplace.offerGroupInformation &&
      containsUnsecuredOffer(marketplace.offerGroupInformation, notLost),
  );
