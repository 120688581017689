import type { SimpleOfferResponse } from '@yoop/server-model/token-info';
import { OfferStatus } from '@yoop/server-model/token-info';
import { requestedAndNotLost } from './requested-and-not-lost';

/**
 * Predicate for requested offers
 * @param offer
 */
export const requested: (offer: SimpleOfferResponse) => boolean = (
  offer: SimpleOfferResponse,
): boolean => requestedAndNotLost(offer) || (offer && offer.offerStatus === OfferStatus.LOST);
