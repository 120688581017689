import type { ErrorResponse } from './error-response';
import { ClientError } from './client-error';
import type { ClientErrorWithResponse } from './client-error-with-response';

/**
 * Represents an `ApiSauce` `CLIENT_ERROR` problem.
 */
export class ClientRequestError<T = any> extends ClientError implements ClientErrorWithResponse<T> {
  public statusCode: number;
  public response: T;

  constructor(statusCode: number, response: T) {
    super(`The client request failed with status code '${statusCode}'`);
    Object.setPrototypeOf(this, ClientRequestError.prototype);

    this.statusCode = statusCode;
    this.response = response;
  }
}

/**
 * ClientRequestError<ErrorResponse> is used in a lot of places.
 *  If it's safe to, it might be worthwhile to export it from a lib and import it throughout usage.
 *  I don't have all the context, but on brief glance it looked like most, if not all, cases were safe.
 *
 * - Travis
 */
export type ClientErrorResponse = ClientRequestError<ErrorResponse>;
