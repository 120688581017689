import {
  ClientError,
  ClientNetworkError,
  ClientRequestError,
  ClientServerError,
  ClientTimeoutError,
  ClientUnauthorizedError,
} from '@yoop/data-access-error';
import { extractArrayParams } from '@yoop/data-access-helper';
import type { ApiResponse, ApisauceInstance } from 'apisauce';
import {
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
} from 'apisauce';
import type { AxiosRequestConfig } from 'axios';
import { isNil } from 'ramda';

export interface ApiControllerConfig {
  onExtractResponseBody?: (response: ApiResponse<unknown>) => void;
  unauthorizedCallback?: (response: ApiResponse<unknown>) => void;
}

export class ApiController {
  protected api: ApisauceInstance;
  protected config: ApiControllerConfig;

  constructor(api: ApisauceInstance, config?: ApiControllerConfig) {
    this.api = api;
    this.config = config;
  }

  protected async del<T>(url: string, params?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.api.delete<T>(url, params, config);
    return this.extractResponseBody(response);
  }

  protected async get<T>(url: string, params?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const { url: fixedUrl, params: fixedParams } = extractArrayParams(url, params);
    const response = await this.api.get<T>(fixedUrl, fixedParams, config);
    return this.extractResponseBody(response);
  }

  protected async post<T>(url: string, params?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.api.post<T>(url, params, config);
    return this.extractResponseBody(response);
  }

  protected async put<T>(url: string, params?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.api.put<T>(url, params, config);
    return this.extractResponseBody(response);
  }

  protected async patch<T>(url: string, params?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.api.patch<T>(url, params, config);
    return this.extractResponseBody(response);
  }

  protected extractResponseBody<T>(response: ApiResponse<T>): T {
    const { status } = response;

    if (status === 401) {
      this.handleUnauthorized(response);
    } else if (!response.ok) {
      throw this.mapProblemToError(response);
    }

    this.config.onExtractResponseBody?.(response);

    //A boolean response of 'false' will be serialized here to null
    if (isNil(response.data)) {
      // in case no data is returned, the result will not be undefined/null
      return {} as T;
    }
    return response.data;
  }

  protected handleUnauthorized(response: ApiResponse<unknown>): void {
    this.config.unauthorizedCallback?.(response);
    throw new ClientUnauthorizedError();
  }

  protected mapProblemToError<T>(response: ApiResponse<T>): ClientError {
    const { problem } = response;

    if (problem === CLIENT_ERROR) {
      return new ClientRequestError(response.status, response.data);
    }

    if (problem === SERVER_ERROR) {
      return new ClientServerError(response.status, response.data);
    }

    if (problem === CONNECTION_ERROR || problem === NETWORK_ERROR) {
      return new ClientNetworkError();
    }

    if (problem === TIMEOUT_ERROR) {
      return new ClientTimeoutError();
    }

    return new ClientError();
  }
}
