import { browserVersion, osVersion } from 'react-device-detect';
import { isNilOrEmpty, isNilOrNaN } from '@yoop/util';
import { isNil } from 'ramda';

export const osVersionOlderThan = (majorVersion: number): boolean => {
  const osVersionNumber = getMajorVersion(osVersion);
  return !isNil(osVersionNumber) ? osVersionNumber < majorVersion : false;
};

export const browserVersionOlderThan = (majorVersion: number): boolean => {
  const browserVersionNumber = getMajorVersion(browserVersion);
  return !isNil(browserVersionNumber) ? browserVersionNumber < majorVersion : false;
};

export const getMajorVersion = (versionString: string | undefined): number | undefined => {
  const majorVersion = versionString?.split('.')?.[0];
  if (!isNilOrEmpty(majorVersion)) {
    const majorVersionNumber = parseInt(majorVersion);
    if (!isNilOrNaN(majorVersionNumber)) {
      return majorVersionNumber;
    }
  }

  return undefined;
};
