import type {
  AccessControlMode,
  EventAccessibility,
  EventIdVerificationMode,
} from './accessibility';
import type {
  EventToken,
  EventTokenDetail,
  ExtrasToken,
  ExtrasTokenDetail,
  PendingToken,
  ResaleToken,
} from './event-token';
import type { MediaResponse } from './media';
import type { MyPriceOffer, MyYoopOffers } from './my-yoop-offers';
import type { EventTransactingTool } from './transacting-tool';

/*
If modified, make sure whitelabel-widgets/docs/docs/model/my-yoop-events.mdx is in sync so docs stay up to date.
 */

export enum EventState {
  Cancelled = 'Cancelled',
  Postponed = 'Postponed',
  Rescheduled = 'Rescheduled',
  Upcoming = 'Upcoming',
}

export interface EventRestriction {
  description: string;
  icon?: MediaResponse;
  id: number;
  secondaryDescription?: string;
  url?: string;
}

export interface BaseEvent {
  id: string;
  vanityName: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  doorsOpenDateTime: string;
  locationName: string;
  idVerificationMode: EventIdVerificationMode;
  refundedInventoryCount: number;
  refundDeadline: string;
  hasSeasonPass: boolean;
  accessibilityType: EventAccessibility;
  accessControlMode: AccessControlMode;
  currency: string;
  defaultMedia?: MediaResponse;
  media?: MediaResponse[];
  announcement?: string;
  state?: EventState;
  nearestTransactingTool?: EventTransactingTool;
}

export interface MyYoopEvent extends BaseEvent {
  eventAccessTokens?: EventToken[];
  pendingTokens?: PendingToken[];
  extrasTokens?: ExtrasToken[];
}

interface MyYoopEventActions {
  canSeeInfo: boolean;
  canBulkTransfer: boolean;
  canBuyMoreYoop: boolean;
  canBuyMoreMerch: boolean;
  canBuyMoreAddons: boolean;
  canSeeReceipt: boolean;
}

export interface MyYoopEventDetail extends BaseEvent, MyYoopEventActions {
  transactingTools: EventTransactingTool[];
  restrictions?: EventRestriction[];
  eventAccessTokens?: EventTokenDetail[];
  extrasTokens?: ExtrasTokenDetail[];
}

export interface MyYoopRequestedEvent extends BaseEvent {
  requestedOffers: MyYoopOffers[];
  myPriceOffers: MyPriceOffer[];
  canSubmitAnotherBid: boolean;
  canSubmitAnotherOffer: boolean;
}

export interface MyYoopResaleEvent {
  id: string;
  vanityName: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  locationName: string;
  accessibilityType: EventAccessibility;
  currency: string;
  defaultMedia?: MediaResponse;
  media?: MediaResponse[];
  announcement?: string;
  state?: EventState;
  payoutDate: string;
  resaleTokens: ResaleToken[];
}

export interface MyYoopResaleEventDetail extends MyYoopRequestedEvent {
  resaleTransactingTool: EventTransactingTool;
  restrictions?: EventRestriction[];
}
