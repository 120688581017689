import { ClientError } from './client-error';

/**
 * Represents an error for HTML codes 401 Unauthorized and 403 Forbidden
 */
export class ClientUnauthorizedError extends ClientError {
  constructor() {
    super('Unauthorized');
    Object.setPrototypeOf(this, ClientUnauthorizedError.prototype);
  }
}
