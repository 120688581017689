import type { SimpleOfferGroupResponse } from '@yoop/server-model/offer-group';
import type { SimpleOfferResponse } from '@yoop/server-model/token-info';
import isNil from 'ramda/src/isNil';

/**
 * Predicate for offer groups with a given predicate on the active offer that is not archived
 * @param predicate
 */
export const offerPredicate =
  (
    predicate: (offer: SimpleOfferResponse) => boolean,
  ): ((offerGroup: SimpleOfferGroupResponse) => boolean) =>
  (offerGroup: SimpleOfferGroupResponse): boolean =>
    !isNil(offerGroup) && predicate(offerGroup.activeOffer);
