import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import { MarketplaceType } from '@yoop/server-model/marketplace';
import {
  MarketplaceState,
  MarketplaceType as ServerMarketplaceType,
} from '@yoop/server-model/marketplace';
import { datetime } from '@yoop/util-datetime';
import type { EventTransactingTool } from '@yoop/whitelabel-data';
import { TransactingToolType } from '@yoop/whitelabel-data';
import isNil from 'ramda/src/isNil';

export const transactingToolFrom = (
  marketplace: SimpleMarketplaceResponse,
): EventTransactingTool => {
  const type = marketplaceTypeFrom(marketplace?.type);
  if (isNil(type)) {
    return undefined;
  }
  return {
    type,
    canBuyAddons: marketplace.addOnMinimumPrice >= 0,
    canBuyMerchandise: marketplace.merchMinimumPrice >= 0,
    canBuyYoop:
      !isNil(marketplace.minimumPrice) &&
      (isNil(marketplace.userTicketLimitRemaining) || marketplace.userTicketLimitRemaining > 0),
    endTime: marketplace.endDateTime,
    startTime: marketplace.startDateTime,
    id: marketplace.id,
  };
};

const marketplaceTypeFrom = (marketplaceType: ServerMarketplaceType): TransactingToolType => {
  switch (marketplaceType) {
    case ServerMarketplaceType.PURCHASE:
      return TransactingToolType.DirectPurchase;
    case ServerMarketplaceType.SECONDARY_EXCHANGE:
      return TransactingToolType.Resale;
    case ServerMarketplaceType.AUCTION:
      return TransactingToolType.MyPrice;
    case ServerMarketplaceType.DRAW:
      return TransactingToolType.WishList;
    default:
      return undefined;
  }
};

//@FIXME: merge with libs/util-marketplace/src/lib/predicate/active.ts
export const isMarketplaceActive = (resale: SimpleMarketplaceResponse) => {
  if (!resale || resale.state === MarketplaceState.CANCELLED) {
    return false;
  }
  const start = datetime.parse(resale.startDateTime);
  const end = datetime.parse(resale.endDateTime);
  return datetime.isLive(start, end, 'millisecond');
};

//Duplicated from libs/util-marketplace/src/lib/helper/get-marketplace-type-priority.ts
export const getMarketplaceTypePriority = (type: MarketplaceType): number => {
  // Draw and auction marketplaces are given the highest priority when
  // multiple marketplaces are active, followed by purchase and finally
  // resale. Because resale can be active at any point in the event, we
  // should only be providing that option to the user if nothing else is
  // open.
  switch (type) {
    case MarketplaceType.DRAW:
    case MarketplaceType.AUCTION:
      return 0;
    case MarketplaceType.PURCHASE:
      return 1;
    default:
      return 2;
  }
};
