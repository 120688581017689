import pino from 'pino';
import { isProductionEnvironment } from './logger-helper';

const inferDefaultLogLevel = (): string => {
  if (process.env.NODE_ENV === 'test' && process.env.DEBUG_TEST == null) {
    return 'silent';
  }

  if (isProductionEnvironment()) {
    return 'info';
  }

  return 'debug';
};

export const log = pino({ level: process.env.LOG_LEVEL || inferDefaultLogLevel() });
