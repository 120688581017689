import type { PortMessage } from '@yoop/whitelabel-data';
import { createStore } from 'zustand/vanilla';

interface CommunicationStore {
  messageChannel: MessageChannel;
  sendMessage: (message: PortMessage) => void;
  sendDataMessage: (action: string, getData: () => Promise<any>) => void;
}

export const communicationStore = createStore<CommunicationStore>((setState, getState) => ({
  messageChannel: new MessageChannel(),
  sendMessage: (message) => getState().messageChannel.port1.postMessage(message),
  sendDataMessage: async (action, getData) => {
    let payload: PortMessage;
    try {
      const data = await getData();
      payload = {
        action,
        data: {
          success: true,
          result: data,
        },
      };
    } catch (error) {
      payload = {
        action,
        data: {
          success: false,
          error: error as any,
        },
      };
    } finally {
      getState().messageChannel.port1.postMessage(payload);
    }
  },
}));
