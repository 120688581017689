import {
  AccessControlIdCheckMode,
  AccessControlMode as ServerAccessControlMode,
} from '@yoop/server-model/event-access-control';
import { AccessibilityType } from '@yoop/server-model/event-accessibility';
import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import type { TokenInfoResponse } from '@yoop/server-model/token-info';
import { OfferSubStatus } from '@yoop/server-model/token-info';
import type {
  SimpleUserActivityEventResponse,
  UserActivityEventDetailResponse,
} from '@yoop/server-model/user-activity';
import { EventState as ServerEventState } from '@yoop/server-model/user-activity';
import { isNilOrEmpty } from '@yoop/util';
import type { BaseEvent } from '@yoop/whitelabel-data';
import {
  AccessControlMode,
  EventAccessibility,
  EventIdVerificationMode,
  EventState,
} from '@yoop/whitelabel-data';
import { mediaFrom } from '@yoop/whitelabel-media';

export const commonEventDataFrom = (
  response: SimpleUserActivityEventResponse | UserActivityEventDetailResponse,
): BaseEvent => {
  return {
    id: response.id,
    name: response.name,
    announcement: response.announcement,
    doorsOpenDateTime: response.doorsOpenDate,
    endDateTime: response.eventEndDate,
    locationName: response.locationName,
    refundDeadline: response.refundDeadline,
    vanityName: response.vanityName,
    startDateTime: response.eventStartDate,
    currency: response.currency,
    media: response.media?.map(mediaFrom),
    defaultMedia: mediaFrom(response.defaultMedia),
    accessibilityType:
      response.eventAccessibilityType === AccessibilityType.LIVE_ATTENDANCE
        ? EventAccessibility.InPerson
        : EventAccessibility.Virtual,
    accessControlMode: accessControlModeFrom(response.accessControlMode),
    state: eventStateFrom(response.state),
    hasSeasonPass: response.tokenInformation?.some(
      ({ seasonMembershipParentData }) => !isNilOrEmpty(seasonMembershipParentData),
    ),
    idVerificationMode: idVerificationModeFrom(response.accessControlIdCheckMode),
    refundedInventoryCount: refundedInventoryCount(
      response.marketplaceInformation,
      response.tokenInformation,
    ),
  };
};
export const eventStateFrom = (state: ServerEventState): EventState => {
  switch (state) {
    case ServerEventState.CANCELLED:
      return EventState.Cancelled;
    case ServerEventState.RESCHEDULED:
      return EventState.Rescheduled;
    case ServerEventState.POSTPONED:
      return EventState.Postponed;
    case ServerEventState.UPCOMING:
      return EventState.Upcoming;
  }
};

const idVerificationModeFrom = (mode: AccessControlIdCheckMode): EventIdVerificationMode => {
  switch (mode) {
    case AccessControlIdCheckMode.FORCE_ID:
      return EventIdVerificationMode.IdRequired;
    case AccessControlIdCheckMode.TRUE_YOOP:
      return EventIdVerificationMode.Verified;
    case AccessControlIdCheckMode.NO_ID:
      return EventIdVerificationMode.None;
  }
};

const accessControlModeFrom = (mode: ServerAccessControlMode): AccessControlMode => {
  switch (mode) {
    case ServerAccessControlMode.YOOP:
      return AccessControlMode.Yoop;
    case ServerAccessControlMode.EXTERNAL:
      return AccessControlMode.External;
  }
};

const refundedInventoryCount = (
  marketplaces: SimpleMarketplaceResponse[],
  tokens: TokenInfoResponse[],
) => {
  const marketplaceRefunded = marketplaces?.reduce((total, { offerGroupInformation }) => {
    const _refunded = offerGroupInformation?.reduce(
      (total, { activeOffer }) =>
        activeOffer?.offerSubStatus === OfferSubStatus.REFUNDED
          ? activeOffer.numberOfTokens ?? 0
          : 0,
      0,
    );
    return _refunded + total;
  }, 0);
  const tokenRefunded = tokens?.reduce(
    (total, { offerResponse, categoryDetail }) =>
      offerResponse?.offerSubStatus === OfferSubStatus.REFUNDED
        ? categoryDetail?.accessTokensPerInventory ?? 1
        : 0,
    0,
  );
  return marketplaceRefunded + tokenRefunded;
};
