import { ApiController } from '@yoop/base-client';
import type { GetClientConsumerInfoResponse } from '@yoop/client-configuration';

export class WhitelabelConfigController extends ApiController {
  public getClientConfiguration(apiKey: string) {
    return this.post<GetClientConsumerInfoResponse>('/client/api-key/public/getConsumerInfo', {
      apiKey,
    });
  }
}
