import type { CardType, PaymentMethodType } from './payment-method';

export enum PaymentMeansVerificationRequirement {
  NOT_REQUIRED = 'NOT_REQUIRED',
  VERIFY_OVER_THRESHOLD = 'VERIFY_OVER_THRESHOLD',
  VERIFY = 'VERIFY',
}

export enum PaymentMeansState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}
/* The means of payment the user has provided */
export interface PaymentMeans {
  /* The card holder name */
  cardHolderName: string;

  /* The user’s card type. Visa, Mastercard etc */
  cardType: CardType;

  /* The two digit country code for this card */
  country: string;

  /* Is this the default card? */
  defaultMeans: boolean;

  /* The card expiration month */
  expirationMonth: number;

  /* The card expiration year */
  expirationYear: number;

  fingerprint: string;

  /* The last 4 digits user’s card */
  lastDigits: string;

  /* The Spreedly id for this payment method */
  externalPaymentMethodId?: string;

  /* The id for this payment method */
  paymentMethodId: string;

  /* The zip/postal code for the card */
  postalCode: string;

  paymentMeansState?: PaymentMeansState;

  /* The user’s payment means type. Now it includes all payment method types*/
  type: PaymentMethodType;

  usageLimitPerEvent: number;

  verificationRequirement?: PaymentMeansVerificationRequirement;
}
