export enum TransactingToolType {
  MyPrice = 'myPrice',
  WishList = 'wishList',
  DirectPurchase = 'directPurchase',
  Resale = 'resale',
}

export interface EventTransactingTool {
  id: string;
  type: TransactingToolType;
  startTime: string;
  endTime: string;
  canBuyYoop: boolean;
  canBuyMerchandise: boolean;
  canBuyAddons: boolean;
}
