export const IFRAME_MESSAGE_TYPE = 'yoop-iframe';
export const IFRAME_PORT_ORIGIN_PARAM = 'portOrigin';
export const IFRAME_ORIGIN_PARAM = 'iframeOrigin';
export const IFRAME_CLOSE_MSG = 'iframe-on-close';
export const ANALYTICS_EVENT = 'analytics-event';

export enum YoopIframeOrigin {
  Login = 'login',
  Transaction = 'transaction',
  MyYoop = 'myYoop',
  CommsManager = 'commsManager',
}

export interface IframeMessageType<T = any> {
  type: string;
  origin: YoopIframeOrigin;
  content?: T;
}

export enum YoopIframeMessageAction {
  UpdateConfig = 'updateConfig',
}
