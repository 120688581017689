import { toLower } from 'ramda';
import { useParams } from 'react-router';
import { Language } from './language';
import { Locale } from './locale';

interface UrlParams {
  lng: string | undefined;
}

export const lowerCasedLocales = {
  [Locale.FRENCH_CA]: toLower(Locale.FRENCH_CA),
  [Locale.ENGLISH_CA]: toLower(Locale.ENGLISH_CA),
  [Locale.SPANISH_US]: toLower(Locale.SPANISH_US),
};

export const useUserLocale = (): Locale => {
  const { lng } = useParams<UrlParams>();

  switch (toLower(lng ?? '')) {
    case lowerCasedLocales[Locale.FRENCH_CA]:
      return Locale.FRENCH_CA;
    case lowerCasedLocales[Locale.ENGLISH_CA]:
      return Locale.ENGLISH_CA;
    case lowerCasedLocales[Locale.SPANISH_US]:
      return Locale.SPANISH_US;
    default:
      return Locale.ENGLISH_US;
  }
};

export const localeFrom = (language: Language) => {
  switch (toLower(language)) {
    case Language.FRENCH:
      return Locale.FRENCH_CA;
    case Language.SPANISH:
      return Locale.SPANISH_US;
    default:
      return Locale.ENGLISH_US;
  }
};
