import type { SimpleOfferResponse } from '@yoop/server-model/token-info';
import { OfferStatus } from '@yoop/server-model/token-info';

/**
 * Predicate for requested offers that are not lost yet.
 * @param offer
 */
export const requestedAndNotLost: (offer: SimpleOfferResponse) => boolean = (
  offer: SimpleOfferResponse,
): boolean =>
  offer &&
  (offer.offerStatus === OfferStatus.DEFAULT ||
    offer.offerStatus === OfferStatus.WON_PAYMENT_PROCESSING_PENDING ||
    offer.offerStatus === OfferStatus.WON_MANUAL_PAYMENT_REQUIRED);
