import type { SimpleMarketplaceResponse } from '@yoop/data-access-model';
import { isNilOrEmpty } from '@yoop/util';
import {
  containsOrphanMarketplace,
  containsUnsecuredOfferGroup,
} from '@yoop/util-marketplace/helper';
import { requested } from '@yoop/util-marketplace/predicate';
import isEmpty from 'ramda/src/isEmpty';

/**
 * Filter to get requested events
 * @param event
 * @param notLost
 */
export const requestedFilter = (
  marketplaces: SimpleMarketplaceResponse[],
  notLost?: boolean,
): boolean => {
  if (isNilOrEmpty(marketplaces)) {
    return false;
  }

  const validMarketplaces = marketplaces.filter(requested);
  if (isEmpty(validMarketplaces)) {
    return false;
  }

  return (
    containsOrphanMarketplace(validMarketplaces) &&
    containsUnsecuredOfferGroup(validMarketplaces, notLost)
  );
};
