/**
 * Adds an array parameter to a URL, that is
 * {
 *   key: [value, value1, value2, ...]
 * }
 * would add ?key=value&key=value1&key=value2&key=...
 * @param key
 * @param value
 * @param url
 */
export const addArrayParamToUrl = (
  key: string,
  value: any[],
  url: string
): string =>
  value.reduce(
    (previousValue: string, keyValue: any): string =>
      previousValue.includes('?')
        ? `${previousValue}&${key}=${keyValue}`
        : `${previousValue}?${key}=${keyValue}`,
    url
  );
