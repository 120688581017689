export interface MyYoopOffers {
  id: number;
  status: OfferStatus;
  description: string;
  yoopCount: number;
  price: number;
  transactingToolId: number;
  offerGroupId: number;
  chance: OfferChance;
  actionEndTime?: string;
}

export interface MyPriceOffer {
  id: number;
  categoryId: number;
  description: string;
  yoopCount: number;
  price: number;
  expireTime: string;
  status: MyPriceOfferStatus;
}

export enum OfferChance {
  Great = 'great',
  Good = 'good',
  Ok = 'ok',
  Poor = 'poor',
  Negligible = 'negligible',
}

export enum OfferStatus {
  Active = 'active',
  PendingResult = 'pendingResult',
  PaymentFailed = 'paymentFailed',
  PendingPayment = 'pendingPayment',
  PendingClaim = 'pendingClaim',
  Lost = 'lost',
}

export enum MyPriceOfferStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Failed = 'failed',
}
