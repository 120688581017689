import type { SimpleMarketplaceResponse } from '@yoop/server-model/marketplace';
import { MarketplaceType } from '@yoop/server-model/marketplace';
import type { TokenInfoResponse } from '@yoop/server-model/token-info';
import {
  Chance,
  OfferStatus as ServerOfferStatus,
  OfferSubStatus,
  type SimpleOfferResponse,
} from '@yoop/server-model/token-info';
import type {
  GetOffersUpgradabilityRequest,
  SimpleUserActivityEventResponse,
  UserActivityEventDetailResponse,
} from '@yoop/server-model/user-activity';
import { BidStatus } from '@yoop/server-model/user-activity';
import { isNilOrEmpty } from '@yoop/util';
import { datetime } from '@yoop/util-datetime';
import type {
  MyYoopEvent,
  MyYoopEventDetail,
  MyYoopOffers,
  MyYoopRequestedEvent,
  UserProfile,
} from '@yoop/whitelabel-data';
import { MyPriceOfferStatus, OfferChance, OfferStatus } from '@yoop/whitelabel-data';
import { mediaFrom } from '@yoop/whitelabel-media';
import isNil from 'ramda/src/isNil';
import { commonEventDataFrom } from './event/common.helper';
import {
  getMarketplaceTypePriority,
  isMarketplaceActive,
  transactingToolFrom,
} from './marketplace.helper';
import { detailTokensFrom, pendingTokensFrom, tokensFrom } from './token.helper';

//duplicated from libs/util-token/src/lib/helper/get-tokens.ts
export const MIN_TOKENS_TO_SHOW_BULK_OPT = 8;

export const myYoopEventFrom = (
  response: SimpleUserActivityEventResponse,
  currentUser: UserProfile,
): MyYoopEvent => {
  const { tokens, extras } = tokensFrom({
    ownedTokens: response.tokenInformation,
    assignedTokens: response.assigneeTokenInformation,
    isResaleOpen: isMarketplaceActive(response.resaleMarketplace),
    currentUserProfile: currentUser,
  });

  return {
    ...commonEventDataFrom(response),
    nearestTransactingTool: transactingToolFrom(response.nextMarketplace),
    extrasTokens: extras,
    pendingTokens: pendingTokensFrom(response.marketplaceInformation),
    eventAccessTokens: tokens,
  };
};

export const myYoopEventDetailFrom = (
  response: UserActivityEventDetailResponse,
  currentUser: UserProfile,
  upgradableOffers: Set<number>,
): MyYoopEventDetail => {
  const isResaleOpen = response.marketplaceInformation.some(
    (marketplace) =>
      marketplace.type === MarketplaceType.SECONDARY_EXCHANGE && isMarketplaceActive(marketplace),
  );
  const { tokens, extras } = detailTokensFrom({
    ownedTokens: response.tokenInformation,
    assignedTokens: response.assigneeTokenInformation,
    isResaleOpen,
    currentUserProfile: currentUser,
    upgradableOffers,
    accessControlSubMode: response.accessControlSubMode,
    //Duplicated logic from 'eventDoorsOpen'
    isDoorsOpen: datetime
      .now()
      .isBetween(response.doorsOpenDate, response.doorsCloseDate, undefined, '[)'),
  });
  const sortedMarketplaces =
    response.marketplaceInformation?.sort((lhs, rhs) => {
      const canBuyYoop = isNil(lhs.minimumPrice);
      if (canBuyYoop === isNil(lhs.minimumPrice)) {
        return getMarketplaceTypePriority(lhs.type) - getMarketplaceTypePriority(rhs.type);
      }
      return canBuyYoop ? 1 : -1;
    }) ?? [];
  const transactingTools = sortedMarketplaces.map(transactingToolFrom);
  const activeMarketplace = transactingTools.find(({ startTime, endTime }) =>
    datetime.now().isBetween(startTime, endTime),
  );
  const numOfAssignableTokens =
    tokens.filter(({ canAssign }) => canAssign).length +
    extras.filter(({ canAssign }) => canAssign).length;
  return {
    ...commonEventDataFrom(response),
    extrasTokens: extras,
    eventAccessTokens: tokens,
    transactingTools,
    restrictions: response.eventRestrictions?.map((restriction) => ({
      id: restriction.id,
      description: restriction.description,
      icon: restriction.icon && mediaFrom(restriction.icon),
      url: restriction.url,
      secondaryDescription: restriction.secondaryDescription,
    })),
    canSeeInfo: !isNilOrEmpty(response.eventRestrictions),
    canBulkTransfer: numOfAssignableTokens > MIN_TOKENS_TO_SHOW_BULK_OPT,
    canBuyMoreYoop: activeMarketplace?.canBuyYoop,
    canBuyMoreMerch: activeMarketplace?.canBuyMerchandise,
    canBuyMoreAddons: activeMarketplace?.canBuyAddons,
    canSeeReceipt: response.tokenInformation?.some(
      ({ offerResponse }) =>
        offerResponse?.offerSubStatus !== OfferSubStatus.REFUNDED && offerResponse?.amount > 0,
    ),
  };
};

export const myYoopRequestedEventFrom = (
  response: SimpleUserActivityEventResponse,
): MyYoopRequestedEvent => {
  let canBidMore = false;
  if (response.exchangeInfo?.exchangeEnabled) {
    canBidMore = datetime
      .now()
      .isBetween(
        datetime.parse(response.exchangeInfo.bidSubmissionWindow?.startTime),
        datetime.parse(response.exchangeInfo.bidSubmissionWindow?.endTime),
      );
  }
  const { offers, canSubmitMoreOffers } = requestedOffersInfoFrom(response.marketplaceInformation);
  return {
    ...commonEventDataFrom(response),
    nearestTransactingTool: transactingToolFrom(response.nextMarketplace),
    requestedOffers: offers,
    myPriceOffers: response.bids?.map((offer) => ({
      id: offer.offerId,
      price: offer.price,
      description: offer.inventoryCategoryName,
      yoopCount: offer.quantity,
      categoryId: offer.inventoryCategoryId,
      expireTime: offer.expireTime,
      status: myPriceOfferStatusFrom(offer.status),
    })),
    canSubmitAnotherBid: canBidMore,
    canSubmitAnotherOffer: canSubmitMoreOffers,
  };
};

const requestedOffersInfoFrom = (marketplaces: SimpleMarketplaceResponse[]) => {
  const offers: MyYoopOffers[] = [];
  let canSubmitMoreOffers = false;
  marketplaces?.forEach(
    ({
      id: marketplaceId,
      type,
      offerGroupInformation,
      parentMarketplaceId,
      userTicketLimitRemaining,
      endDateTime,
    }) => {
      if (
        (type !== MarketplaceType.AUCTION && type !== MarketplaceType.DRAW) ||
        !isNil(parentMarketplaceId)
      ) {
        return;
      }
      const marketplaceEnd = datetime.parse(endDateTime);
      if (datetime.isPast(marketplaceEnd)) {
        offerGroupInformation?.forEach(({ id: offerGroupId, description, activeOffer }) => {
          const offer = finishedRequestedOfferFrom({
            description,
            marketplaceType: type,
            activeOffer,
            marketplaceId,
            offerGroupId,
          });
          if (!isNil(offer)) {
            offers.push(offer);
          }
        });
      } else {
        offerGroupInformation?.forEach(({ id: offerGroupId, description, activeOffer }) => {
          if (activeOffer.offerStatus !== ServerOfferStatus.DEFAULT) {
            //active marketplace might have lost offers in case of fraud, etc.
            return;
          }
          canSubmitMoreOffers = canSubmitMoreOffers || userTicketLimitRemaining > 0;
          offers.push({
            id: parseInt(activeOffer.id),
            offerGroupId: parseInt(offerGroupId),
            price: activeOffer.amount ?? 0,
            transactingToolId: parseInt(marketplaceId),
            description,
            yoopCount: activeOffer.numberOfTokens,
            status: OfferStatus.Active,
            chance: type === MarketplaceType.AUCTION ? chanceFrom(activeOffer.chance) : undefined,
          });
        });
      }
    },
  );
  return { offers, canSubmitMoreOffers };
};
const finishedRequestedOfferFrom = ({
  activeOffer,
  offerGroupId,
  marketplaceId,
  marketplaceType,
  description,
}: {
  activeOffer: SimpleOfferResponse;
  marketplaceId: string;
  offerGroupId: string;
  description: string;
  marketplaceType: MarketplaceType;
}) => {
  if (!activeOffer || activeOffer.offerStatus === ServerOfferStatus.WON_TOKEN_ASSIGNED) {
    return undefined;
  }

  let actionEndTime: string;
  let status: OfferStatus = OfferStatus.PendingResult;
  switch (activeOffer.offerStatus) {
    case ServerOfferStatus.LOST:
      status = OfferStatus.Lost;
      break;
    case ServerOfferStatus.WON_MANUAL_PAYMENT_REQUIRED:
      actionEndTime = activeOffer.retryEndTime;
      if (activeOffer.offerSubStatus === OfferSubStatus.AUTO_PAYMENT_FAILED) {
        status = OfferStatus.PaymentFailed;
      } else {
        status = OfferStatus.PendingPayment;
      }
      break;
    case ServerOfferStatus.WON_PAYMENT_SUCCESSFUL:
      actionEndTime = activeOffer.retryEndTime;
      status = OfferStatus.PendingClaim;
      break;
    default:
  }
  return {
    id: parseInt(activeOffer.id),
    offerGroupId: parseInt(offerGroupId),
    price: activeOffer.amount ?? 0,
    transactingToolId: parseInt(marketplaceId),
    description,
    yoopCount: activeOffer.numberOfTokens,
    actionEndTime,
    status,
    chance:
      marketplaceType === MarketplaceType.AUCTION ? chanceFrom(activeOffer.chance) : undefined,
  };
};

const chanceFrom = (chance: Chance): OfferChance => {
  switch (chance) {
    case Chance.OK:
      return OfferChance.Ok;
    case Chance.GOOD:
      return OfferChance.Good;
    case Chance.GREAT:
      return OfferChance.Great;
    case Chance.NEGLIGIBLE:
      return OfferChance.Negligible;
    case Chance.POOR:
      return OfferChance.Poor;
  }
};

const myPriceOfferStatusFrom = (status: BidStatus): MyPriceOfferStatus => {
  switch (status) {
    case BidStatus.OPEN:
      return MyPriceOfferStatus.Active;
    case BidStatus.EXPIRED:
      return MyPriceOfferStatus.Expired;
    case BidStatus.CANCELLED:
      return MyPriceOfferStatus.Cancelled;
    case BidStatus.FAILED:
      return MyPriceOfferStatus.Failed;
  }
  //BidStatus.FILLED
  return undefined;
};

export const upgradeOfferRequestFrom = (
  tokens: TokenInfoResponse[],
): GetOffersUpgradabilityRequest | undefined => {
  const upgradeGroup: Record<string, number[]> = {};
  tokens?.forEach(({ offerResponse, tokenId }) => {
    if (!isNilOrEmpty(offerResponse)) {
      upgradeGroup[offerResponse.id] = upgradeGroup[offerResponse.id] ?? [];
      upgradeGroup[offerResponse.id].push(parseInt(tokenId));
    }
  });

  if (isNilOrEmpty(upgradeGroup)) {
    return undefined;
  }

  return {
    offerUpgradeOptionsRequestItems: Object.entries(upgradeGroup).map(([key, value]) => ({
      sourceOfferId: parseInt(key),
      inventoryItemIdsForUpgrade: value,
    })),
  };
};
