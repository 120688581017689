import { DEFAULT_ERROR_MESSAGE } from './constants';

/**
 * Represents any `ApiSauce` error.
 */
export class ClientError extends Error {
  constructor(message: string = DEFAULT_ERROR_MESSAGE) {
    super(message);
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}
