import type { GetMediaResponse } from './get-media-response';
import type { MediaResponse } from './media-response';
import { isNilOrEmpty } from '@yoop/util';

export const mapGetMediaResponseToMediaResponse = (response: GetMediaResponse): MediaResponse => {
  if (isNilOrEmpty(response)) {
    return null;
  }

  return {
    ...response,
    id: `${response.id}`,
    url: response.url,
  };
};
