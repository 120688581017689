import { ApiController } from '@yoop/base-client';
import type { UiUserActivityTabConfigurationResponse } from '@yoop/server-model/user-activity';

export enum UserAuthenticationState {
  EMAIL_UNVERIFIED = 'EMAIL_UNVERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  BLACK_LISTED = 'BLACK_LISTED',
}

//Duplicated from libs/data-access-model/src/lib/user-profile-response.ts;
export interface UserProfileApiResponse {
  globalUserId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailAddress: string;
  profilePictureUrl: string;
  profilePictureVerificationStatus: boolean;
  seenProfilePictureVerificationState: boolean;
  userAuthenticationState: UserAuthenticationState;
}

export class UserController extends ApiController {
  public getUserProfile(): Promise<UserProfileApiResponse> {
    return this.get('user/profile');
  }

  public getUserTabs(clientId: number): Promise<UiUserActivityTabConfigurationResponse> {
    return this.get('/user/activityConfiguration', { clientId });
  }
}
