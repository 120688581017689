import { isNil } from 'ramda';
import { isNilOrEmpty } from './is-nil-or-empty';

const baseMap = <T, U>(
  array: T[],
  shouldAdd: (value: U) => boolean,
  mapper: (item: T, index?: number) => U | null,
): U[] => {
  if (isNilOrEmpty(array)) {
    return [];
  }
  return array.reduce<U[]>((result, item, currentIndex) => {
    const mapped = mapper(item, currentIndex);
    if (shouldAdd(mapped)) {
      result.push(mapped);
    }
    return result;
  }, []);
};

export const mapNonNil = <T, U>(array: T[], mapper: (item: T, index?: number) => U | null): U[] => {
  return baseMap(array, (value) => !isNil(value), mapper);
};

export const partition = <T>(
  array: T[],
  predicate: (item: T) => boolean,
): { pass: T[]; fail: T[] } => {
  return array.reduce(
    (passFails, element) => {
      return predicate(element)
        ? { pass: [...passFails.pass, element], fail: passFails.fail }
        : {
            pass: passFails.pass,
            fail: [...passFails.fail, element],
          };
    },
    { pass: [], fail: [] },
  );
};

export const mapNonNilOrEmpty = <T, U>(
  array: T[],
  mapper: (item: T, index?: number) => U | null,
): U[] => {
  return baseMap(array, (value) => !isNilOrEmpty(value), mapper);
};
