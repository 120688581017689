import type { SimpleMarketplaceResponse } from '@yoop/data-access-model';
import { datetime } from '@yoop/util-datetime';
import { cancelled } from './cancelled';

/**
 * Predicate for active marketplaces
 * @param marketplace
 */
export const active = (marketplace: SimpleMarketplaceResponse): boolean =>
  marketplace &&
  !cancelled(marketplace) &&
  datetime.isLive(
    datetime.parse(marketplace.startDateTime),
    datetime.parse(marketplace.endDateTime),
    'millisecond',
  );
