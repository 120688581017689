import { ClientError } from './client-error';
import { DEFAULT_ERROR_MESSAGE } from './constants';

/**
 * Represents an `ApiSauce` `CONNECTION_ERROR` or `NETWORK_ERROR` problem.
 */
export class ClientNetworkError extends ClientError {
  constructor() {
    super(DEFAULT_ERROR_MESSAGE);
    Object.setPrototypeOf(this, ClientNetworkError.prototype);
  }
}
