import { communicationStore } from '@yoop/message-channel';
import { apiClientStore } from '@yoop/whitelabel-api-client';
import type {
  AuthenticationResult,
  EventDetailRequestData,
  EventTokenActionData,
  GetConfigActionData,
  PortMessage,
} from '@yoop/whitelabel-data';
import { IFRAME_MESSAGE_TYPE, YoopIframeOrigin } from '@yoop/whitelabel-data';
import { CommsMessageActions } from '@yoop/whitelabel-data';
import { onGetEventsData } from '@yoop/whitelabel-event';
import { appStore } from '@yoop/whitelabel-manager-store';
import { MyYoopActions } from '@yoop/whitelabel-my-yoop';
import { apiConfig } from './environments/api-environment';
import { getUserProfile } from './user/user-actions.helper';

const { messageChannel } = communicationStore.getState();
messageChannel.port1.onmessage = (message) => {
  switch (message.data?.action) {
    case CommsMessageActions.GetEvents: {
      onGetEventsData();
      break;
    }
    case CommsMessageActions.Tabs:
      MyYoopActions.onGetUserTabs();
      break;
    case CommsMessageActions.InPersonEventsList:
      MyYoopActions.onGetInPersonEventList();
      break;
    case CommsMessageActions.VirtualEventsList:
      MyYoopActions.onGetVirtualEventList();
      break;
    case CommsMessageActions.RequestedEventsList:
      MyYoopActions.onGetRequestedEventList();
      break;
    case CommsMessageActions.EventDetail: {
      const _portMessage = message.data as PortMessage<EventDetailRequestData>;
      MyYoopActions.onGetEventDetail(_portMessage.data.eventId);
      break;
    }
    case CommsMessageActions.ResaleEventsList:
      MyYoopActions.onGetResaleEventList();
      break;
    case CommsMessageActions.AssignTokenToCurrentUser: {
      const _portMessage = message.data as PortMessage<EventTokenActionData>;
      MyYoopActions.onAssignTokenToCurrentUser(undefined, _portMessage.data.tokenId);
      break;
    }
    case CommsMessageActions.ResendTokenInvite: {
      const _portMessage = message.data as PortMessage<EventTokenActionData>;
      MyYoopActions.onResendTokenInvite(_portMessage.data.tokenId);
      break;
    }
    case CommsMessageActions.ReturnToken: {
      const _portMessage = message.data as PortMessage<EventTokenActionData>;
      MyYoopActions.onReturnToken(_portMessage.data.tokenId);
      break;
    }
    case CommsMessageActions.RevokeToken: {
      const _portMessage = message.data as PortMessage<EventTokenActionData>;
      MyYoopActions.onRevokeToken(_portMessage.data.tokenId);
      break;
    }
    case CommsMessageActions.Authentication:
      if (message.data.data?.isAuthenticated) {
        //@TODO: pass profile from consumer app.
        // ApiActions.onAuthenticated();
      }
      break;
    case CommsMessageActions.GetConfig: {
      const _portMessage = message.data as PortMessage<GetConfigActionData>;
      const { sendDataMessage } = communicationStore.getState();
      sendDataMessage(CommsMessageActions.GetConfig, async () => {
        const { apiClient } = apiClientStore.getState();
        const config = await apiClient.clientConfigController.getClientConfiguration(
          _portMessage.data.apiKey,
        );
        appStore.getState().updateClient(config.clientId);
        return config;
      });
      break;
    }
  }
};

window.parent?.postMessage(
  {
    type: IFRAME_MESSAGE_TYPE,
    origin: YoopIframeOrigin.CommsManager,
    content: 'init',
  },
  document.referrer,
  [messageChannel.port2],
);

apiClientStore.getState().initializeClient({
  baseURL: apiConfig.api,
  withCredentials: true,
  unauthorizedCallback: () => {
    appStore.getState().updateCurrentUser(undefined);
    communicationStore.getState().sendMessage({
      action: CommsMessageActions.Authentication,
      data: {
        authenticated: false,
      } as AuthenticationResult,
    });
  },
});

//Attempts to get user profile in case it was already logged in to .yoop.app
getUserProfile()
  .then((profile) => {
    appStore.getState().updateCurrentUser(profile);
    communicationStore.getState().sendMessage({
      action: CommsMessageActions.Authentication,
      data: {
        authenticated: true,
        profile,
      } as AuthenticationResult,
    });
  })
  .catch(() => {
    appStore.getState().updateCurrentUser(undefined);
    communicationStore.getState().sendMessage({
      action: CommsMessageActions.Authentication,
      data: {
        authenticated: false,
      } as AuthenticationResult,
    });
  });
