import type { SimpleOfferGroupResponse } from '@yoop/data-access-model';
import { offerPredicate } from '@yoop/util-offer-group/predicate';
import { requested } from '../predicate/requested';
import { requestedAndNotLost } from '../predicate/requested-and-not-lost';

/**
 * Checks if a group of offers contains at least one unsecured offer
 * @param offerGroups
 */
export const containsUnsecuredOffer: (
  offerGroups: SimpleOfferGroupResponse[],
  notLost: boolean,
) => boolean = (offerGroups: SimpleOfferGroupResponse[], notLost: boolean) =>
  offerGroups.some(offerPredicate(notLost ? requestedAndNotLost : requested));
