import { communicationStore } from '@yoop/message-channel';
import { AccessibilityType } from '@yoop/server-model/event-accessibility';
import { UiUserActivityTabType } from '@yoop/server-model/user-activity';
import { isNilOrEmpty, mapNonNil } from '@yoop/util';
import { apiClientStore } from '@yoop/whitelabel-api-client';
import { CommsMessageActions, MyYoopTabType } from '@yoop/whitelabel-data';
import { appStore } from '@yoop/whitelabel-manager-store';
import isNil from 'ramda/src/isNil';
import { resaleEventFrom } from './event/resale.helper';
import {
  onAssignTokenToCurrentUser,
  onResendTokenInvite,
  onReturnToken,
  onRevokeToken,
} from './my-yoop-token-actions';
import {
  myYoopEventDetailFrom,
  myYoopEventFrom,
  myYoopRequestedEventFrom,
  upgradeOfferRequestFrom,
} from './my-yoop.helper';

const tabTypeFrom = (type: UiUserActivityTabType): MyYoopTabType => {
  switch (type) {
    case UiUserActivityTabType.LIVE_ATTENDANCE_EVENTS:
      return MyYoopTabType.InPerson;
    case UiUserActivityTabType.ACTIVE_OFFER_EVENTS:
      return MyYoopTabType.Requested;
    case UiUserActivityTabType.STREAMING_EVENTS:
      return MyYoopTabType.Virtual;
    case UiUserActivityTabType.SEASON_MEMBERSHIPS:
      return MyYoopTabType.Membership;
    case UiUserActivityTabType.SELLING_EVENT:
      return MyYoopTabType.Selling;
    default:
      return undefined;
  }
};

const onGetInPersonEventList = async () => {
  const { currentUser, clientId } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.InPersonEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.eventController.getUserEvents({
      clientId,
      type: AccessibilityType.LIVE_ATTENDANCE,
    });
    return response.userActivityList.map((event) => myYoopEventFrom(event, currentUser));
  });
};

const onGetVirtualEventList = async () => {
  const { currentUser, clientId } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.VirtualEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.eventController.getUserEvents({
      clientId,
      type: AccessibilityType.STREAMING,
    });
    return response.userActivityList.map((event) => myYoopEventFrom(event, currentUser));
  });
};

const onGetRequestedEventList = async () => {
  const { clientId } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.RequestedEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.eventController.getUserRequestedEvents({
      clientId,
    });
    return response.userActivityList.map((event) => myYoopRequestedEventFrom(event));
  });
};

const onGetResaleEventList = async () => {
  const { clientId } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.ResaleEventsList, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.eventController.getUserSellingEvents({
      clientId,
    });
    return response.eventList.map((event) => resaleEventFrom(event));
  });
};

const onGetUserTabs = async () => {
  const { clientId } = appStore.getState();

  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.Tabs, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.userController.getUserTabs(clientId);
    return mapNonNil(response.tabResponses, ({ tabType, showPastEvents, default: isDefault }) => {
      const type = tabTypeFrom(tabType);
      if (isNil(type)) {
        return undefined;
      }
      return {
        type,
        hasPastEvents: showPastEvents,
        isDefault,
      };
    });
  });
};

const onGetEventDetail = async (eventId: number) => {
  const { currentUser } = appStore.getState();
  const { sendDataMessage } = communicationStore.getState();
  sendDataMessage(CommsMessageActions.EventDetail, async () => {
    const { apiClient } = apiClientStore.getState();
    const response = await apiClient.eventController.getUserActivityDetails(`${eventId}`);
    const upgradeRequest = upgradeOfferRequestFrom([
      ...(response.tokenInformation ?? []),
      ...(response.assigneeTokenInformation ?? []),
    ]);
    const upgradableOffers = new Set<number>();
    if (!isNilOrEmpty(upgradeRequest)) {
      try {
        const upgradeResult = await apiClient.eventController.getOffersUpgradability(
          upgradeRequest,
        );
        upgradeResult.offerUpgradableResponseItems?.forEach(({ offerId, hasUpgradeOptions }) => {
          if (hasUpgradeOptions) {
            upgradableOffers.add(offerId);
          }
        });
      } catch {}
    }
    return myYoopEventDetailFrom(response, currentUser, upgradableOffers);
  });
};

export const MyYoopActions = {
  onGetUserTabs,
  onGetVirtualEventList,
  onGetInPersonEventList,
  onGetRequestedEventList,
  onGetResaleEventList,
  onGetEventDetail,
  onRevokeToken,
  onResendTokenInvite,
  onReturnToken,
  onAssignTokenToCurrentUser,
};
