import type { ItemOption } from '@yoop/server-model/inventory-category';
import type { CategoryDetailResponse } from '@yoop/server-model/inventory-category';
import type { SeatConfigurationType } from '@yoop/server-model/seating';
import type { InventoryItemSeatingData, TokenPrice } from '@yoop/server-model/token-info';

export enum PayoutType {
  PAYPAL = 'PAYPAL',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export interface PayoutMethodResponse {
  id: number;
  type: PayoutType;
  username: string;
  status: PayoutMethodStatus;
}

export enum PayoutMethodStatus {
  LAST_ATTEMPT_FAILED = 'LAST_ATTEMPT_FAILED',
}

export enum PayoutStatus {
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface ResaleInventoryItem {
  inventoryItemId: number;
  parentInventoryItemId?: number;
  row: string;
  rowCode: string;
  seat: string;
  seatCode: string;
  section: string;
  sectionCode: string;
  inventoryItemSeatingData?: InventoryItemSeatingData;
  choiceData?: ItemOption[];
}

export interface ResaleItem extends ResaleInventoryItem {
  categoryDetail: CategoryDetailResponse;
}

export interface ResaleOfferGroup {
  description: string;
  id: number;
  categoryDetailResponse: CategoryDetailResponse;
  payoutStatus: PayoutStatus;
  reservePrice: number;
  seatConfigurationType: SeatConfigurationType;
  payoutMethod: PayoutMethodResponse;
  payoutReceipt: TokenPrice;
  soldInventoryItems: ResaleInventoryItem[];
  unsoldInventoryItems: ResaleInventoryItem[];
  fineprint: string;
}
